import * as React from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import Content from "./components/templates/Content";
import { objectToArray } from "./util/utilFunctions";
import schema from "./data/schema";

const Routes = () => {
  return (
    <Switch>
      {objectToArray(schema)
        // We shouldn't be filtering out routes, just what shows in the default nav.
        .reduce((arr, next, index) => {
          arr.push(
            <Route
              key={next.key + "-id-tab"}
              path={"/" + next.value.route + "/:id/:tab"}
              render={props => <Content schemaItem={next.value} {...props} />}
            />
          );
          arr.push(
            <Route
              key={next.key + "-id"}
              path={"/" + next.value.route + "/:id"}
              render={props => {
                return (
                  <Redirect
                    to={
                      "/" +
                      [
                        next.value.route,
                        props.match.params.id,
                        next.value.tabOrder[0]
                      ].join("/")
                    }
                  />
                );
              }}
            />
          );
          arr.push(
            <Route
              key={next.key}
              path={"/" + next.value.route}
              render={props => <Content schemaItem={next.value} />}
            />
          );

          return arr;
        }, [])}

      <Route exact path="/" render={() => <Redirect to="/sections" />} />
    </Switch>
  );
};

export default Routes;
