import React from "react";
import ShtaAPI from "../../api/ShtaApi";
import Button, { BUTTON_TYPE } from "../atoms/Button";
import TabList from "../molecules/TabList";
import { groupBy } from "../../util/utilFunctions";
import "./BulkAddEntity.css";

class BulkAddEntity extends React.Component {
  constructor(props) {
    super(props);
    this.state = { listData: [], isLoading: false };
  }

  componentDidMount() {
    this.requestList();
  }

  requestList() {
    var shtaAPI = new ShtaAPI();
    this.setState({ listData: [], isLoading: true });
    shtaAPI
      .list(this.props.schemaItem.type, this.props.itemIds)
      .then(listData => {
        const filteredListData =
          listData.data
            .filter(listItem => !this.props.currentItems.includes(listItem.id))
            .map(listItem => ({ ...listItem, selected: false })) || [];
        this.setState({
          listData: filteredListData,

          isLoading: false,
          groupedListData: groupBy(filteredListData, this.props.schemaItem)
        });
      });
  }

  onListItemClick = id => {
    this.setState(({ listData }) => {
      const index = listData.findIndex(listItem => listItem.id === id);
      listData[index] = {
        ...listData[index],
        selected: !listData[index].selected
      };
      return {
        listData,
        groupedListData: groupBy(listData, this.props.schemaItem)
      };
    });
  };

  render() {
    const { schemaItem, onAdded, onCancel } = this.props;
    const { listData, groupedListData } = this.state;
    const totalSelected = listData.filter(({ selected }) => selected).length;
    return (
      <div className="add-card__container">
        <div className="card__container add-entity__card with--no-border">
          {groupedListData && (
            <TabList
              listData={groupedListData}
              schemaItem={schemaItem}
              onListItemClick={this.onListItemClick}
            />
          )}
          <div className="bulk-add-entity__button-container">
            <Button
              buttonType={BUTTON_TYPE.secondary}
              onClick={() => onCancel()}
            >
              CANCEL
            </Button>
            <Button
              onClick={() =>
                onAdded(listData.filter(({ selected }) => selected))
              }
              buttonType={BUTTON_TYPE.primary}
              disabled={totalSelected === 0}
            >
              Add{" "}
              {`${totalSelected !== 0 ? totalSelected + " " : ""}${
                totalSelected === 1 ? schemaItem.nameSingular : schemaItem.name
              }`}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default BulkAddEntity;
