
export function localTesting() {
  if (!process.env.REACT_APP_BASE_URL) {
    console.error("No base api url has been configured, please update the build for a specific environment. Falling back to local sandbox testing")
    return true
  }
  return process.env.REACT_APP_BASE_URL === "local-testing";
}

/**
 * Converts an object to an array of objects
 * For object in the new property array, there are two properties:
 *  * `key`: for the original property name
 *  * `value`: for the original value
 *
 * @export
 * @param {*} obj
 * @returns {{key:any, value: any}[]}
 */
export function objectToArray(obj) {
  return Object.keys(obj).map(key => {
    return {
      key: key,
      value: obj[key]
    };
  });
}

/**
 * Converts a string to camel case
 *
 * @export
 * @param {string} str
 * @returns {string}
 */
export function toCamelCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .reduce((a, s, i) => {
      return i === 0 ? s : a + s[0].toUpperCase() + s.substr(1);
    }, "");
}

/**
 * Checks to see if a property is defined on a base object without throwing exceptions if a part of that object is not defined.
 * Example:
 *   - `propHasValue({}, 'props.firstlayer.secondlayer.deaperNesting')` will return false
 *   - `propHasValue({name: {isGreat: true}}, 'name.isGreat')` will return true
 *   - `propHasValue({name: {isGreat: false}}, 'name.isGreat')` will also return true because we are testing against having a value, not truthiness.
 *   - `propHasValue({name: {isGreat: true}}, 'name.isGreat', false)` will return false since we will testing that the input value has equality with the property
 *
 * @export
 * @param {*} obj
 * @param {string} propName
 * @param {*} [value]
 * @returns
 */
export function propHasValue(obj, propName, value) {
  let foundProp = getNestedValue(obj, propName);
  return value ? foundProp === value : typeof foundProp !== "undefined";
}

/**
 * Checks to see if a property is defined on a base object without throwing exceptions if a part of that object is not defined.
 * Example:
 *   - `getNestedValue({}, 'props.firstlayer.secondlayer.deaperNesting')` will return undefined
 *   - `getNestedValue({name: {isGreat: true}}, 'name.isGreat')` will return true
 *   - `getNestedValue({name: {isGreat: false}}, 'name.isGreat')` will return false
 *
 * @export
 * @param {*} obj
 * @param {string} propName
 * @param {any} defaultValue
 * @returns
 */
export function getNestedValue(obj, propName, defaultValue = undefined) {
  return (
    propName.split(".").reduce((prop, nextprop) => {
      // yes, using only 2 equals here is intentional, it will test for undefined or null, since that is what we want
      return prop == null ? undefined : prop[nextprop];
    }, obj) || defaultValue
  );
}

/**
 * Combines css class names
 *
 * @export
 * @param {string} className
 * @param {string[]} additional
 * @returns
 */
export function combineClassNames(className, ...additional) {
  additional.unshift(className);
  return additional
    .join(" ")
    .split(" ")
    .reduce((arr, cl) => {
      if (arr.indexOf(cl) > -1) {
        return arr;
      }
      arr.push(cl);
      return arr;
    }, [])
    .join(" ")
    .trim();
}

/**
 * Checks to see if the entity satisfies any of the conditions
 *
 * @export
 * @param {string[]} [conditions] --eg : ["personType:Admin", "personType:Volunteer"],
 * @param {{[x: string]: any}} entity
 * @returns
 */
export function satifiesCondition(conditions, entity) {
  //conditions: ["personType:Admin", "personType:Volunteer"],
  if (!conditions) {
    return true;
  }
  return conditions.some(condition => {
    let [propName, requiredValue] = condition.split(":");
    return entity[propName] === requiredValue;
  });
}

export function populateStringFromScope(str, scope) {
  const templateReg = /{{[a-zA-Z0-9.:|]*}}/g;
  let replaceable = str.match(templateReg) || [];
  let returnVal = replaceable.reduce((ns, next) => {
    let [propName, defaultValue] = next.slice(2, -2).split("|");
    return ns.replace(next, getNestedValue(scope, propName, defaultValue || ""));
  }, str);
  if (returnVal === "undefined") {
    return undefined;
  }
  return returnVal;
}

export function numberToPixels(num) {
  return num + "px";
}

export function toBoolean(val) {
  let booleanNegatives = [0, "false", null, undefined];
  if (booleanNegatives.indexOf(val) > -1) {
    return false;
  }
  return !!val;
}

/**
 * Add Change handlers that take care of updating the entity with the remote version
 *
 * @export
 * @param {*} entity
 * @param {{remove: (string, string) => Promise, update: (string, any) => Promise }} api
 *
 * @returns {{subscribe: ({})=> () => void), update: ({}) => void, remove: () => void, getSchema: () => any, [x: string]: any}}
 */
export function addChangeHandler(entity, schemaItem, api, history) {
  let subscribers = [];
  let count = 0;

  let funcs = {};
  let subscribe = subscriber => {
    count++;
    let key = `sub-${count}`;
    subscribers[key] = subscriber;
    return () => {
      delete subscribers[key];
    };
  };

  let notify = newEntity => {
    objectToArray(subscribers).forEach(kv => {
      if (!kv.value) {
        return;
      }
      kv.value(newEntity);
    });
  };

  let update = changes => {
    let newEntity = {
      ...entity,
      ...changes,
      ...funcs
    };
    return api.update(schemaItem.type, newEntity).then(() => {
      entity = newEntity;
      notify(newEntity);
    });
  };

  let remove = () => {
    return api.remove(schemaItem.type, entity.id).then(() => {
      history.goBack();
    });
  };

  funcs = {
    subscribe: subscribe,
    update: update,
    remove: remove,
    getSchema: () => schemaItem
  };

  return {
    ...entity,
    ...funcs
  };
}

/**
 *
 *
 * @param {*} list
 * @param {*} schemaItem
 * @returns {{key: string, index?: any, list: any[], title?: string, subtitle?: string, groupBy?: {}, groupedItemValue: string}[]}
 * @memberof EntityList
 */
export function groupBy(list, schemaItem) {
  if (!schemaItem.groupBy) {
    return [{ list: list, key: "singleList" }];
  }
  let groupBy = schemaItem.groupBy;
  // as part of a groupby, we will create a few extra properties
  // *
  let items = list.reduce((agg, item) => {
    let groupedValue = getNestedValue(item, groupBy.propName);
    if (groupBy.map && groupBy.map[String(groupedValue)]) {
      groupedValue = groupBy.map[String(groupedValue)];
    }
    // init or grab object and list
    agg[groupedValue] = agg[groupedValue] || {};
    agg[groupedValue].list = agg[groupedValue].list || [];

    agg[groupedValue].list.push(item);
    // we need to actually aggregate these objects into something new
    agg[groupedValue].aggregate = {
      groupedItemValue: groupedValue,
      ...agg[groupedValue].aggregate,
      ...item,
      groupedItemCount: agg[groupedValue].list.length
    };
    return agg;
  }, {});
  let groups = objectToArray(items);
  // no point to show grouping for single group.
  if (groups.length === 1) {
    return [{ list: list, key: "singleList" }];
  }
  return objectToArray(items).map(({ value }) => {
    return {
      key: value.aggregate.groupedItemValue,
      groupBy: groupBy,
      index: groupBy.orderMap
        ? groupBy.orderMap[value.aggregate.groupedItemValue]
        : 1,
      list: value.list,
      groupedItemValue: value.aggregate.groupedItemValue,
      title: groupBy.title
        ? populateStringFromScope(groupBy.title, value.aggregate)
        : null,
      subtitle: groupBy.subtitle
        ? populateStringFromScope(groupBy.subtitle, value.aggregate)
        : null
    };
  });
}
