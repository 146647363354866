import React from "react";
import { NavLink } from "react-router-dom";
import NavigationText from "../atoms/NavigationText";
import Icon from "../atoms/Icon";
import "./MainNavItem.css";

export default function MainNavItem({ name, routeName, type }) {
  let selected = window.location.pathname.indexOf(`/${routeName}`) === 0;
  let color = selected ? "rgb(0, 117, 255)" : "rgb(4, 26, 38)";
  return (
    <div className="main-nav-item__container">
      <NavLink className="main-nav-item__link" to={"/" + routeName}>
        <Icon
          name={type}
          uniqueKey={type + "nav"}
          color={color}
          className="main-nav-item__link__icon"
        />
        <NavigationText>{name}</NavigationText>
      </NavLink>
    </div>
  );
}
