import React from "react";
import { Link } from "react-router-dom";

import { Subhead } from "../../atoms/Typeography";
import Card from "../../atoms/Card";
import { addChangeHandler, toBoolean } from "../../../util/utilFunctions";
import ShtaApi from "../../../api/ShtaApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./TaskCard.css";

export default class TaskCard extends React.Component {
  constructor(props) {
    super(props);
    // Instead of this subscription pattern, we should be moving to providers and context for managing 
    let entity = addChangeHandler(
      this.props.entity,
      this.props.schemaItem,
      new ShtaApi(),
      {goBack:() => {
        props.regroup()
      }}
    );
    this.unsubscribe = entity.subscribe(newEntity => {
      if (props.regroup) {
        props.regroup();
      }
    });
    this.state = {
      entity: entity,
      disabled: false
    };
  }

  componentWillUnmount() {
    if (typeof this.unsubscribe !== "function") { return }
    this.unsubscribe()
  }

  onChange(newVal) {
    this.setState({
      disabled: true
    });
    this.state.entity.update({
      completed: !toBoolean(this.state.entity.completed)
    });
  }

  onDelete() {
    this.state.entity.remove()
  }

  render() {
    const { entity } = this.state;
    if (!entity || !entity.getSchema || entity.getSchema().type !== "tasks" || !entity.assignedTo) {
      return null
    }
    const { type, id, name } = entity.assignedTo;
    const htmlId = `${entity.name}-${entity.id}` 
    return (
      <Card padding={true}>
        <div className="task-card__content">
          <div
            className="flx flx--row"
            onClick={() => this.onChange()}
          >
            <input
              id={htmlId}
              className="with--h-margin-2"
              type="checkbox"
              disabled={entity.disabled}
              checked={entity.completed}
              onChange={e => this.onChange()}
            />
            <label
                className={`entity-title__header${entity.disabled ? " disabled" : ""}`}
                disabled={entity.disabled}
                htmlFor={htmlId}
              >
                {entity.name}
            </label>
            {/* <ListType className="entity-title__header">{entity.name}</ListType> */}
          </div>
          <div className="flx flx--row">
          {/* Change this out for avatars and listing */}
            <Subhead className="entity-title__header with--h-margin-1">
              Assigned to:{" "}
            </Subhead>
            <Link
              to={`/${type}/${id}`}
              className="task-card__assigned-link"
            >
              {name}
            </Link>
            <div className="task-card__remove-button" onClick={() => entity.remove()} role="button">
              <FontAwesomeIcon icon={faTimes} color={"rgb(67, 84, 93)"} size="lg" />
            </div>
          </div>
        </div>
      </Card>
    );
  }
}
