import React from 'react';
import { combineClassNames } from '../../util/utilFunctions';
import './Typeography.css';

/**
 * @param {object} props
 * @param {string} props.className
 * @param {any} [props.children]
 * @param {string} [props.className]
 * @returns
 */
export const Headline1 = ({children, className, ...others}) => {
    return <h1 className={combineClassNames('hdg hdg--headline-1', className)} {...others} >{children}</h1>
}

/**
 * @param {object} props
 * @param {string} props.className
 * @param {any} [props.children]
 * @param {string} [props.className]
 * @returns
 */
export const Headline2 = ({children, className, ...others}) => {
    return <h2 className={combineClassNames('hdg hdg--headline-2', className)} {...others} >{children}</h2>
}

/**
 * @param {object} props
 * @param {string} props.className
 * @param {any} [props.children]
 * @param {string} [props.className]
 * @returns
 */
export const Headline3 = ({children, className, ...others}) => {
    return <h3 className={combineClassNames('hdg hdg--headline-3', className)} {...others} >{children}</h3>
}

/**
 * @param {object} props
 * @param {string} props.className
 * @param {any} [props.children]
 * @param {string} [props.className]
 * @returns
 */
export const Subhead = ({children, className, ...others}) => {
    return <h4 className={combineClassNames('hdg hdg--subhead', className)} {...others} >{children}</h4>
}

/**
 * @param {object} props
 * @param {string} props.className
 * @param {any} [props.children]
 * @param {string} [props.className]
 * @returns
 */
export const Eyebrow = ({children, className, ...others}) => {
    return <h5 className={combineClassNames('hdg hdg--eyebrow', className)} {...others} >{children}</h5>
}

/**
 * @param {object} props
 * @param {string} props.className
 * @param {any} [props.children]
 * @param {string} [props.className]
 * @returns
 */
export const ButtonText = ({children, className, ...others}) => {
    return <h5 className={combineClassNames('hdg hdg--button-txt', className)} {...others} >{children}</h5>
}

/**
 * @param {object} props
 * @param {string} props.className
 * @param {any} [props.children]
 * @param {string} [props.className]
 * @returns
 */
export const ListType = ({children, className, ...others}) => {
    return <p className={combineClassNames('txt txt--list-type', className)} {...others} >{children}</p>
}

/**
 * @param {object} props
 * @param {string} props.className
 * @param {any} [props.children]
 * @param {string} [props.className]
 * @returns
 */
export const BodyCopy = ({children, className, ...others}) => {
    return <p className={combineClassNames('txt txt--body-copy', className)} {...others} >{children}</p>
}
