import React from "react";
import Card from "../../atoms/Card";

import "./ProjectCard.css";
import { getNestedValue } from "../../../util/utilFunctions";
import { TemplateCardContent } from "./TemplateCard";

export default function ProjectCard({entity, schemaItem}) {
  let priorityIndicator = getNestedValue(entity, "priority", "low")
  // this is to overcome if somehow it was set to the wrong type...
  priorityIndicator = priorityIndicator.toLowerCase ? priorityIndicator.toLowerCase() : "low"
  return (
    <Card padding={false} className="with--position-relative">
      <div className={`project-card--${priorityIndicator}`}>
        <div className="with--margin-2">
          <TemplateCardContent entity={entity} schemaItem={schemaItem}/>
        </div>
      </div>
    </Card>
  );
}
