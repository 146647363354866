import React, { Component } from "react";
import ShtaAPI from "../../api/ShtaApi";
import EntityTitle from "../molecules/Titles/EntityTitle";
import EntityTabContent from "../organisms/EntityTabContent";
import { addChangeHandler } from "../../util/utilFunctions";
import LoadIndicator from "../atoms/LoadIndicator";

class EntityPage extends Component {
  constructor(props) {
    super(props);
    this.shtaApi = new ShtaAPI();
    this.state = {
      isLoading: true,
      entityValue: null
    };
  }

  componentDidMount() {
    if (this.props.entity) {
      this.handleEntityLoaded(this.props.entity);
      return;
    }
    this.shtaApi
      .retrieve(this.props.schemaItem.type, this.props.match.params.id)
      .then(entityValue => {
        this.handleEntityLoaded(entityValue.data);
      });
  }

  handleEntityLoaded(entityValue) {
    if (!entityValue) {
      // handle items that are not found
      this.props.history.replace(`/${this.props.schemaItem.route}`);
      return;
    }
    let entity = addChangeHandler(
      entityValue,
      this.props.schemaItem,
      this.shtaApi,
      this.props.history
    );
    this.unsubscribe = entity.subscribe(changedEntity =>
      this.onEntityChanged(changedEntity)
    );
    this.setState({ entityValue: entity, isLoading: false });
  }

  componentWillUnmount() {
    if (typeof this.unsubscribe !== "function") {
      return;
    }
    this.unsubscribe();
  }

  onEntityChanged(changedEntity) {
    this.setState({ entityValue: changedEntity, isLoading: false });
  }

  renderLoading() {
    return <LoadIndicator size={25} />;
  }

  render() {
    if (this.state.isLoading) {
      return this.renderLoading();
    }
    return (
      <div className="flx--one with--scroll">
        <EntityTitle
          schemaItem={this.props.schemaItem}
          entity={this.state.entityValue}
        />
        <EntityTabContent
          match={this.props.match}
          schemaItem={this.props.schemaItem}
          entity={this.state.entityValue}
        />
      </div>
    );
  }
}

export default EntityPage;
