import React from 'react';
import { Eyebrow } from '../atoms/Typeography';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import './AddFeature.css';

export default function AddFeature(props) {
    return (
        <div className='add-feature__container' onClick={()  => props.addHandler()}>
            <FontAwesomeIcon icon={faPlus} />
            <Eyebrow className="add-feature__title">{`Add ${props.title}`}</Eyebrow>
        </div>
    );
}
