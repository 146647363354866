import React from 'react';
import Button, { BUTTON_TYPE } from '../atoms/Button';
import './EditingButtons.css';
import LoadIndicator from '../atoms/LoadIndicator';

const EditingButtons = (props) => {

  return (
    <div className="editing-buttons__outer-container">
      <div className="editing-buttons__inner-container">
        {
          !props.saving ? (
            <>
            <Button
              onClick={props.onCancel}
              buttonType={BUTTON_TYPE.secondary}
              disabled={props.disabled}
            >Cancel</Button>
            <Button
              onClick={props.onSave}
              buttonType={BUTTON_TYPE.primary}
              disabled={props.disabled}
            >Save</Button>
          </>) : <LoadIndicator size={12}/>
        }
        
      </div>

    </div>
  )
}

export default EditingButtons