import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Subhead } from "../atoms/Typeography";
import { useState } from "react";
import "./Accordion.css"


export default function Accordion(props) {
    const { header, className, style, children} = props
    const [expanded, setHidden] = useState(!!props.startExpanded);
    const exansionIcon = expanded ? faChevronUp : faChevronDown
  
    const onToggleExpand = () => {
      setHidden(!expanded);
    };


    return (
      <div className={className} style={style} >
        <button
          className="flx flx--row flx--space-around accordion__button"
          onClick={onToggleExpand}
        >
            <Subhead className="accordion__title">{header}</Subhead>
            <div className="accordion__expansion-icon">
                <FontAwesomeIcon icon={exansionIcon} />
            </div>
        </button> 
        <div aria-hidden={!expanded} className={`accordion__content ${expanded ? "expanded" : "closed"}`}>
            {children}
        </div>
      </div>
    );
  }
  