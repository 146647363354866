import React from "react";
import { Eyebrow, Headline3, Subhead } from "../../atoms/Typeography";
import Card from "../../atoms/Card";

export default function SectionCard(props) {
  return (
    <Card padding="true">
      <Eyebrow className="entity-title__sub-header txt--medium">
        {props.entity.mainTrailDistance + " miles"}
      </Eyebrow>
      <Headline3 className="entity-title__header">
        {props.entity.startTitle}
      </Headline3>
      <Subhead className="entity-title__header">
        {"to "}
        <span className="txt--font-wt-normal">{props.entity.endTitle}</span>
      </Subhead>
    </Card>
  );
}
