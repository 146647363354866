const WEEKDAYS = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export function dayOfWeek() {
  return WEEKDAYS[new Date().getDay()];
}

export const formatDDMMYY = (date, separater = '') => {
  if (typeof date === 'number') {
    date = new Date(date)
  }
  if (!date) {
    return ""
  }
  return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join(separater)
}

export const formatYYYYMMDDWithDash = dateMMDDYYYY => {
  var dateSplit = String(dateMMDDYYYY).split("/");
  var months = dateSplit[0];
  var days = dateSplit[1];
  var years = dateSplit[2];

  return years + "-" + months + "-" + days;
};

export const formatYYYYMMDDWithSlash = dateYYYYMMDD => {
  var years = dateYYYYMMDD.substr(0, 4);
  var months = dateYYYYMMDD.substr(4, 2);
  var days = dateYYYYMMDD.substr(6, 2);

  return months + "/" + days + "/" + years;
};

export const formatYYYYMMDD = dateMMDDYYYY => {
  var years = parseInt(dateMMDDYYYY.substr(0, 4));
  var months = parseInt(dateMMDDYYYY.substr(4, 2));
  var days = parseInt(dateMMDDYYYY.substr(6, 2));

  return new Date(years, months - 1, days);
};

export function formatDateYYYYMMDD(date) {
  return date
    .toISOString()
    .split("T")[0]
    .split("-")
    .join("");
}

export const formatTime = value => {
  var hours = value.getHours() % 12 || 12; // show midnight & noon as 12

  return (
    hours +
    (value.getMinutes() < 10 ? ":0" : ":") +
    value.getMinutes() +
    (value.getHours() < 12 ? " am" : " pm")
  );
};

export const removeDateSlashes = someDate => {
  if (someDate) {
    return someDate.replace(/\//g, "");
  } else {
    return "";
  }
};