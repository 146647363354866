import React from "react";
import ShtaApi from "../../api/ShtaApi";
import "./FileUpload.css";

export default props => {
  if (props.editing) {
    return <FileUpload {...props} />;
  }
  return null;
};

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ShtaApi();
    this.state = {
      file: null
    };
  }

  onChange(uploadedFile) {
    let file = uploadedFile.target.files[0];
    this.setState({
      uploadedFile: file
    });
  }

  onFormSubmit(uploadedFile) {
    uploadedFile.preventDefault();
    this.api.uploadFile(uploadedFile);
  }

  render() {
    return (
      <div className="flx flx--row input__container">
        <div className="upload-btn-wrapper">
          {/* <form onSubmit={this.onFormSubmit}> */}
          <input
            type="file"
            name="file"
            onChange={uploadedFile => this.onChange(uploadedFile)}
          />
          <button type="submit">Upload</button>
          {/* </form> */}
        </div>
      </div>
    );
  }
}
