import React from "react";
import Button, { BUTTON_TYPE } from "./Button";
import { Headline3, Subhead } from "./Typeography";
import { populateStringFromScope } from "../../util/utilFunctions";
import "./Delete.css";

const Delete = ({
  schemaItem,
  parentEntity,
  onDelete,
  onCancel,
  itemPendingDeletion
}) => (
  <div className="add-card__container">
    <div className="card__container delete__card">
      <Headline3 className="bulk-add-entity__header-text">
        Remove {schemaItem.nameSingular}
      </Headline3>
      <Subhead className="bulk-add-entity__header-text delete__header-text">
        {`Are you sure you wish to remove ${populateStringFromScope(
          schemaItem.title,
          itemPendingDeletion
        )} from the ${populateStringFromScope(
          parentEntity.getSchema().title,
          parentEntity
        )} ${parentEntity.getSchema().nameSingular}`}
      </Subhead>
      <div className="bulk-add-entity__button-container">
        <Button buttonType={BUTTON_TYPE.secondary} onClick={() => onCancel()}>
          CANCEL
        </Button>
        <Button onClick={() => onDelete()} buttonType={BUTTON_TYPE.primary}>
          REMOVE {schemaItem.nameSingular}
        </Button>
      </div>
    </div>
  </div>
);

export default Delete;
