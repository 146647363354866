import React from "react";
import { objectToArray, satifiesCondition } from "../../util/utilFunctions";
import EditingGroup from "../molecules/EditingGroup";
import SingleImage from "../molecules/SingleImage";
const DETAILS_KEY = "Details";

export default function Details({ schemaItem, entity }) {
  // here we are mapping fields to figure out what to show in this view per the schema.js
  let detailFieldMap = objectToArray(schemaItem.fields)
    .filter(isDetailItem)
    .reduce(groupByEditing, {});
  let detailFields = objectToArray(detailFieldMap).reduce(toArray, []);
  let textFields = detailFields.filter(isTextComponent);
  let otherFields = detailFields.filter(x => !isTextComponent(x));
  let rightField = otherFields.filter(
    x => x.editingItems[0].placement === "right"
  )[0];

  const getDetailsRightPane = () => {
    if (!rightField) {
      return;
    }
    return <SingleImage entity={entity} propName={rightField.key} />;
  };

  return (
    <div className="flx flx--column">
      <div className="flx--grid two--col">
        <div>
          <div className="with--margin-2 flx flx--column ">
            {textFields.map(tf =>
              showEditingGroup(tf, entity) ? (
                <EditingGroup
                  key={tf.title}
                  title={tf.title}
                  entity={entity}
                  editingItems={tf.editingItems}
                  schemaItem={schemaItem}
                />
              ) : null
            )}
          </div>
        </div>
        <div>{getDetailsRightPane()}</div>
      </div>
      <div className="An Image Grid here..." />
    </div>
  );
}

function showEditingGroup(group, entity) {
  if (!group.editingItems) {
    return true;
  }
  return group.editingItems.some(item => {
    return satifiesCondition(item.conditions, entity);
  });
}

function isDetailItem(kv) {
  return kv.value.tab === DETAILS_KEY;
}

function groupByEditing(groups, next) {
  if (next.value.editingGroup) {
    groups[next.value.editingGroup] = groups[next.value.editingGroup] || {
      title: next.value.editingGroup,
      component: "EditingGroup",
      editingItems: []
    };
    groups[next.value.editingGroup].editingItems.push({
      key: next.key,
      ...next.value
    });
  } else {
    groups[next.key] = {
      title: next.value.title || next.key,
      component: next.value.component,
      key: next.key,
      editingItems: [{ key: next.key, ...next.value }]
    };
  }
  return groups;
}

function toArray(arr, kv) {
  arr.push(kv.value);
  return arr;
}

function isTextComponent(item) {
  return ["Input", "EditingGroup", "TextArea"].some(comp => {
    return item.component.indexOf(comp) > -1;
  });
}
