/**
 * # Schema for the Asset entity.
 *
 */

export default {
    name: "Assets",
    nameSingular: "Asset",
    showInNav: true,
    index: 3,
    route: "assets",
    type: "assets",
    urlList: "trail/assets",
    url: "trail/asset",
    fields: {
        name: {
            title: "Asset Name",
            tab: "Details",
            editingGroup: "Information",
            showViewState: false,
            component: "Input"
        },
        locationMiles: {
            title: "Trail Mile Marker",
            tab: "Details",
            editingGroup: "Information",
            showViewState: false,
            component: "Input"
        },
        type: {
            title: "Asset Type",
            tab: "Details",
            editingGroup: "Information",
            showViewState: false,
            component: "Dropdown",
            options: ["Bridge", "Boardwalk", "Stairway", "Wetland"]
        },
        segment: {
            title: "Segment",
            tab: "Details",
            editingGroup: "Information",
            component: "EntityLink:segments",
            placeholder: "Select segment"
        },
        section: {
            title: "Section",
            tab: "Details",
            editingGroup: "Information",
            component: "EntityLink:sections",
            placeholder: "Select section"
        },
        installDate: {
            title: "Installation Date",
            tab: "Details",
            editingGroup: "Information",
            component: "Input:date",
            placeholder: "Enter Install date"
        },
        condition: {
            title: "Condition",
            tab: "Details",
            editingGroup: "Information",
            component: "Dropdown",
            options: ["Good", "Fair", "Poor"],
            placeholder: "Select condition"
        },
        length: {
            title: "Length",
            tab: "Details",
            editingGroup: "Information",
            component: "Input",
            placeholder: "Enter length"
        },
        waypoint: {
            title: "Waypoint",
            tab: "Details",
            editingGroup: "Information",
            component: "Input",
            placeholder: "Enter waypoint info"
        },
        description: {
            title: "Description",
            tab: "Details",
            component: "TextArea",
            placeholder: "Write a description..."
        },
        map: {
            tab: "Details",
            placement: "right",
            component: "SingleImage"
        },
        photos: {
            tab: "Details",
            component: "ImageGrid"
        },
        projects: {
            tab: "Projects",
            component: "EntityList:projects"
        },
        people: {
            tab: "People",
            component: "EntityList:people"
        },
        files: {
            tab: "Files",
            component: "EntityList:fileLinks"
        },
        notes: {
            tab: "Notes",
            component: "Notes"
        }
    },
    tabOrder: [
        "Details",
        "Projects",
        "People", 
        "Files",
        "Notes"
    ],
    startingFields: ["name", "type", "installDate"],
    title: "{{name}}",
    subtitle: "Mile {{locationMiles|0}} | {{type}}",
    jsonObjectName: "asset_data",
    icon: "faIgloo",
    filterOptions: [
        "type"
    ]
};