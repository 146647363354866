import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";

// Amplify Auth constructs
import Amplify from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
import { Auth } from "aws-amplify";
import { MainNav } from "./components/organisms/MainNav";
import "./App.css";
import "./Overrides.scss";
import { AppContext } from "./AppContext";

Amplify.configure({
  "aws_project_region": process.env.REACT_APP_PROJECT_REGION,
  "aws_cognito_identity_pool_id": process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  "aws_cognito_region": process.env.REACT_APP_COGNITO_REGION,
  "aws_user_pools_id": process.env.REACT_APP_USER_POOLS_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      logout: () => {}
    };
    Auth.currentAuthenticatedUser().then(user => {
      this.setState({ user });
    });
  }

  logout() {
    Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err));
  }

  render() {
    return (
      <AppContext.Provider
        value={{ user: this.state.user, logout: () => this.logout() }}
      >
        <BrowserRouter>
          <div className="main__layout">
            <MainNav />
            <Routes />
          </div>
        </BrowserRouter>
      </AppContext.Provider>
    );
  }
}

export const UnauthApp = () => {
  return <App />;
};

export const AuthApp = withAuthenticator(App, {
  // Render a sign out button once logged in
  includeGreetings: false
});
