import React from "react";
import "./EntityTitle.css";
import { ButtonText } from "../../atoms/Typeography";
import { NavLink } from "react-router-dom";
import { SectionTitle, PersonTitle, DefaultTitle } from "./Titles";
import Icon from "../../atoms/Icon";
import Button, { BUTTON_TYPE } from "../../atoms/Button";
import LoadIndicator from "../../atoms/LoadIndicator";

const Titles = {
  sections: SectionTitle,
  people: PersonTitle,
};

export default class EntityTitle extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      showDelete: false
    }
  }

  remove() {
    this.setState({deleting:true});
    this.props.entity.remove()
      .then(() => this.setState({ deleting: false }))
  }

  renderEditing() {
    return !this.state.showDelete ? (
      <Icon
        name="editing"
        uniqueKey="entity-editing"
        color="#FFF"
        className="with--h-margin-2 size--24 with--pointer"
        onClick={() => this.setState({ showDelete: true })}
      />
    ) : (
        <div className="flx flx--column">
          {
            this.state.deleting ? <LoadIndicator size={12} /> : (
              <>
                <Button
                  onClick={() => this.setState({ showDelete: false })}
                  buttonType={BUTTON_TYPE.secondary}
                >Cancel</Button>
                <Button
                  onClick={() => this.remove()}
                  buttonType={BUTTON_TYPE.primary}
                >Delete</Button>
              </>
            )
          }
        </div>
      )
  }

  render() {
    let { schemaItem, entity } = this.props
    let TitleComp = Titles[schemaItem.type] || DefaultTitle;

    return (
      <div className="entity-title__container">
        <div className="flx flx--row flx--space-between">
          <div className="flx flx--column">
            <TitleComp {...this.props} />
          </div>
          {
            this.renderEditing()
          }
        </div>
        <div className="entity-title__tabs">
          {
            schemaItem.tabOrder.map((tabName) => {
              return (
                <NavLink
                  key={tabName}
                  className="entity-title__tab"
                  to={"/" + [schemaItem.route, entity.id, tabName].join("/")}
                >
                  <ButtonText className="txt--light with--margin-1">{tabName}</ButtonText>
                </NavLink>
              )
            })
          }
        </div>
      </div>
    );
  }
}