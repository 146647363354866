import React from "react";
import Details from "./Details";
import EntityListTab from "./EntityListTab";
import Notes from "./Notes";
// import Files from "./Files";
import { objectToArray } from "../../util/utilFunctions";

const ComponentMap = {
  Details,
  EntityList: EntityListTab,
  // Files,
  Notes
};

const getComponent = (schemaItem, tabName) => {
  let tabComponentName = objectToArray(schemaItem.fields).reduce(
    (name, { value }) => {
      if (value.tab !== tabName) {
        return name;
      }
      if (value.tab === "Details") {
        return "Details";
      }
      return value.component.split(":")[0];
    },
    ""
  );
  return ComponentMap[tabComponentName];
};

const EntityTabContent = props => {
  let Comp = getComponent(props.schemaItem, props.match.params.tab);
  return (
    <div className="with--scroll">
      <Comp {...props} tabName={props.match.params.tab} />
    </div>
  );
};

export default EntityTabContent;
