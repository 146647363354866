/**
 * # Schema for the Project entity.
 *
 */

export default {
  name: "Projects",
  nameSingular: "Project",
  showInNav: true,
  index: 1,
  route: "projects",
  type: "projects",
  urlList: "maint/projects",
  url: "maint/project",
  fields: {
    name: {
      title: "Project Name",
      tab: "Details",
      editingGroup: "Information",
      showViewState: false,
      component: "Input"
    },
    phase: {
      title: "Project Phase",
      tab: "Details",
      editingGroup: "Information",
      showViewState: false,
      component: "Dropdown",
      options: [
        "Issue Identified",
        "Planning",
        "In Process",
        "Wrap Up",
        "Completed"
      ]
    },
    type: {
      title: "Project Type",
      tab: "Details",
      editingGroup: "Information",
      showViewState: false,
      component: "Dropdown",
      options: ["Maintainence", "Repair", "Replacement", "New Asset"]
    },
    priority: {
      title: "Priority",
      tab: "Details",
      editingGroup: "Information",
      component: "Dropdown",
      options: ["Urgent", "High", "Medium", "Completed"]
    },
    currentCost: {
      title: "Current Cost",
      tab: "Details",
      editingGroup: "Information",
      component: "Input:number",
      placeholder: "Enter actual costs"
    },
    estimatedCost: {
      title: "Estimated Cost",
      tab: "Details",
      editingGroup: "Information",
      component: "Input:number",
      placeholder: "Estimate project costs"
    },
    totalVolunteerHours: {
      title: "Total Volunteer Hours",
      tab: "Details",
      editingGroup: "Information",
      component: "Input:number",
      placeholder: "Enter hours"
    },
    segment: {
      title: "Segment",
      tab: "Details",
      editingGroup: "Information",
      component: "EntityLink:segments",
      placeholder: "Select segment"
    },
    section: {
      title: "Section",
      tab: "Details",
      editingGroup: "Information",
      component: "EntityLink:sections",
      placeholder: "Select section"
    },
    location: {
      title: "Location",
      tab: "Details",
      editingGroup: "Information",
      component: "EntityLink:{{parentType[assets,campsites,trailheads]}}",
      showWithoutValue: false
    },
    description: {
      title: "Description",
      tab: "Details",
      component: "TextArea",
      placeholder: "Write a description..."
    },
    map: {
      tab: "Details",
      placement: "right",
      component: "SingleImage"
    },
    photos: {
      tab: "Details",
      component: "ImageGrid"
    },
    tasks: {
      tab: "Tasks",
      component: "EntityList:tasks"
    },
    people: {
      tab: "People",
      component: "EntityList:people"
    },
    files: {
      tab: "Files",
      component: "EntityList:fileLinks"
    },
    notes: {
      tab: "Notes",
      component: "Notes"
    }
  },
  tabOrder: [
    "Details",
    "Tasks",
    "People",
    "Files",
    "Notes"
  ],
  startingFields: ["name", "type", "phase", "priority"],
  jsonObjectName: "project_data",
  title: "{{name}}",
  subtitle: "{{type}} | {{phase}}",
  groupBy: {
    propName: "phase",
    map: {
      "Issue Identified": "Active",
      Planning: "Active",
      "In Process": "Active",
      "Wrap Up": "Active",
      undefined: "Active",
      Completed: "Completed"
    },
    orderMap: {
      Active: 0,
      Completed: 1
    },
    subtitle: "{{groupedItemValue}} Projects"
  },
  filterBy: {
    propName: "",
    map: {}
  }
};
