import React from "react";
import EntityList from "../organisms/EntityList";
import ListTitle from "../molecules/ListTitle";

export default function EntityListPage(props) {
  return (
    <div className="flx flx--column entity-list__container">
      <ListTitle {...props} />
      <EntityList {...props} />
    </div>
  );
}
