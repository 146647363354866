import Input from "./molecules/Input";
import TextArea from "./molecules/TextArea";
import Dropdown from "./molecules/Dropdown";
import EntityLink from "./molecules/EntityLink";
import Checkbox from "./molecules/Checkbox";
import FileUpload from "./molecules/FileUpload";
import Button from "./atoms/Button";

export const COMPONENT_MAP = {
  Input,
  TextArea,
  Dropdown,
  EntityLink,
  Checkbox,
  FileUpload,
  Button
};

export const getComponentFromString = componetName => {
  return COMPONENT_MAP[componetName] || Input;
};
