import React from "react";

export const Tent = ({ classKey }) => {
  // TODO: There is an issue with this svg that is causing errors in the log.
  return (
    <>
      <path
        className={`cls--${classKey}`}
        d="M22.8,22.31H1.2a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5H22.8a.5.5,0,0,1,.5.5A.51.51,0,0,1,22.8,22.31Z"
      />
      <path
        className={`cls--${classKey}`}
        d="M2.94,22.31a.47.47,0,0,1-.25-.07.5.5,0,0,1-.19-.68L13.54,2a.5.5,0,0,1,.87.49l-11,19.61A.51.51,0,0,1,2.94,22.31Z"
      />
      <path
        className={`cls--${classKey}`}
        d="M21.06,22.31a.51.51,0,0,1-.44-.26L9.59,2.44A.5.5,0,0,1,10.46,2l11,19.61a.5.5,0,0,1-.19.68A.47.47,0,0,1,21.06,22.31Z"
      />
      <path
        className={`cls--${classKey}`}
        d="M15.55,22.31a.49.49,0,0,1-.44-.26L12,16.52,8.89,22.05A.5.5,0,7.08,4,7.08a.49.49,0,0,1-.19.68A.44.44,0,0,1,15.55,22.31Z"
      />
    </>
  );
};

export const Project = ({ classKey }) => (
  <>
    <path
      className={`cls--${classKey}`}
      d="M1.68,22.06l1.94-5.64L18.19,1.86l3.69,3.69L7.32,20.12ZM4.5,17,3.3,20.44l3.48-1.2L20.47,5.55,18.19,3.27Zm2.55,2.72h0Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M18.83,8.4a.51.51,0,0,1-.36-.15l-3-3a.5.5,0,1,1,.71-.71l3,3a.5.5,0,0,1,0,.71A.49.49,0,0,1,18.83,8.4Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M7.05,20.18A.51.51,0,0,1,6.69,20l-3-3a.5.5,0,0,1,0-.71.48.48,0,0,1,.7,0l3,3a.48.48,0,0,1,0,.7A.5.5,0,0,1,7.05,20.18Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M8.75,12.5a.49.49,0,0,1-.35-.15L1.74,5.69,5.43,2l6.66,6.66a.5.5,0,0,1,0,.71.5.5,0,0,1-.7,0l-6-6L3.15,5.69l6,6a.5.5,0,0,1,0,.7A.51.51,0,0,1,8.75,12.5Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M18.05,22l-6.66-6.66a.5.5,0,0,1,0-.71.5.5,0,0,1,.7,0l6,6,2.28-2.28-6-6a.5.5,0,0,1,0-.7.5.5,0,0,1,.71,0l6.66,6.66Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M4.55,8.29a.5.5,0,0,1-.36-.14.51.51,0,0,1,0-.71L5.69,6a.49.49,0,0,1,.7.7L4.9,8.15A.47.47,0,0,1,4.55,8.29Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M6.65,10.4a.47.47,0,0,1-.35-.15.5.5,0,0,1,0-.71L7.79,8.05a.5.5,0,0,1,.71.71L7,10.25A.47.47,0,0,1,6.65,10.4Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M13.84,17.59a.5.5,0,0,1-.35-.85L15,15.24a.5.5,0,1,1,.71.71L14.2,17.44A.51.51,0,0,1,13.84,17.59Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M16,19.69a.51.51,0,0,1-.36-.15.5.5,0,0,1,0-.7l1.49-1.5a.51.51,0,0,1,.71,0,.5.5,0,0,1,0,.71L16.3,19.54A.5.5,0,0,1,16,19.69Z"
    />
  </>
);

export const Person = ({ classKey }) => (
  <>
    <path
      className={`cls--${classKey}`}
      d="M12,21.79A9.79,9.79,0,1,1,21.79,12,9.8,9.8,0,0,1,12,21.79ZM12,3.21A8.79,8.79,0,1,0,20.79,12,8.8,8.8,0,0,0,12,3.21Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M12,13.2A3.68,3.68,0,0,1,8.45,9.39,3.68,3.68,0,0,1,12,5.59a3.68,3.68,0,0,1,3.55,3.8A3.68,3.68,0,0,1,12,13.2Zm0-6.61a2.69,2.69,0,0,0-2.55,2.8A2.69,2.69,0,0,0,12,12.2a2.69,2.69,0,0,0,2.55-2.81A2.69,2.69,0,0,0,12,6.59Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M7.21,20.41a.5.5,0,0,1-.5-.5v-3a2.84,2.84,0,0,1,2.84-2.84h4.9a2.84,2.84,0,0,1,2.84,2.84v3a.5.5,0,1,1-1,0v-3a1.84,1.84,0,0,0-1.84-1.84H9.55a1.84,1.84,0,0,0-1.84,1.84v3A.5.5,0,0,1,7.21,20.41Z"
    />
  </>
);

export const MapIcon = ({ classKey }) => (
  <>
    <path
      className={`cls--${classKey}`}
      d="M4.26,21.79A.52.52,0,0,1,4,21.7a.49.49,0,0,1-.21-.41V4.38a.5.5,0,0,1,.35-.47L9.59,2.09a.5.5,0,0,1,.66.47V19.47a.51.51,0,0,1-.34.48L4.42,21.77A.41.41,0,0,1,4.26,21.79Zm.5-17V20.6l4.49-1.49V3.25Zm5,14.73h0Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M15.24,21.79a.5.5,0,0,1-.16-1l5.15-1.71V3.25L15.4,4.86a.52.52,0,0,1-.64-.32.5.5,0,0,1,.32-.63l5.49-1.82a.5.5,0,0,1,.66.47V19.47a.5.5,0,0,1-.35.48L15.4,21.77A.47.47,0,0,1,15.24,21.79Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M15.24,4.88l-.16,0L9.59,3a.5.5,0,0,1-.31-.63.49.49,0,0,1,.63-.32L15.4,3.91a.5.5,0,0,1-.16,1Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M15.24,21.79a.44.44,0,0,1-.16,0L9.59,20A.5.5,0,0,1,9.91,19l5.49,1.82a.5.5,0,0,1-.16,1Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M15.24,17.11a.53.53,0,0,1-.39-.18c-.12-.14-2.86-3.53-2.86-6.2a3.25,3.25,0,1,1,6.5,0c0,2.67-2.75,6.06-2.87,6.2A.49.49,0,0,1,15.24,17.11Zm0-8.63A2.25,2.25,0,0,0,13,10.73c0,1.77,1.52,4,2.25,5.06.73-1,2.25-3.3,2.25-5.06A2.25,2.25,0,0,0,15.24,8.48Z"
    />
    <circle className="cls-2" cx="15.24" cy="10.73" r="0.49" />
    <path
      className={`cls--${classKey}`}
      d="M15.24,6.06a.5.5,0,0,1-.5-.5V4.38a.5.5,0,0,1,.5-.5.51.51,0,0,1,.5.5V5.56A.51.51,0,0,1,15.24,6.06Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M15.24,21.79a.5.5,0,0,1-.5-.5V19.11a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v2.18A.5.5,0,0,1,15.24,21.79Z"
    />
  </>
);

export const Bell = ({ classKey }) => (
  <>
    <path
      className={`cls--${classKey}`}
      d="M12,21.79a2.62,2.62,0,0,1-2.62-2.62.5.5,0,0,1,1,0,1.62,1.62,0,1,0,3.24,0,.5.5,0,0,1,1,0A2.62,2.62,0,0,1,12,21.79Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M17.84,18H6.15a1.67,1.67,0,0,1,0-3.34V10.8a5.84,5.84,0,0,1,4-5.54.5.5,0,0,1,.63.31.51.51,0,0,1-.31.64,4.84,4.84,0,0,0-3.3,4.59v3.83a1,1,0,0,1-1,1,.67.67,0,0,0,0,1.34H17.84a.67.67,0,1,0,0-1.34,1,1,0,0,1-1-1V11a5.12,5.12,0,0,0-3.31-4.82.5.5,0,0,1-.3-.64.5.5,0,0,1,.63-.31,6.15,6.15,0,0,1,4,5.77v3.6h0a1.67,1.67,0,1,1,0,3.33Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M13.71,6.24a.5.5,0,0,1-.26-.08.5.5,0,0,1-.17-.68A1.5,1.5,0,1,0,10.5,4.7a1.47,1.47,0,0,0,.22.77.51.51,0,0,1-.18.69A.49.49,0,0,1,9.86,6,2.44,2.44,0,0,1,9.5,4.7a2.5,2.5,0,0,1,5,0A2.57,2.57,0,0,1,14.14,6,.52.52,0,0,1,13.71,6.24Z"
    />
  </>
);

export const Campfire = ({ classKey }) => (
  <>
    <path
      className={`cls--${classKey}`}
      d="M17.73,22.09a2.66,2.66,0,0,1-1-.2l-11-4.48a2.68,2.68,0,0,1-1.46-3.49l.15-.38a2.68,2.68,0,0,1,3.49-1.47l11,4.49A2.67,2.67,0,0,1,20.36,20l-.15.39A2.7,2.7,0,0,1,17.73,22.09ZM17.1,21a1.67,1.67,0,0,0,2.18-.91l.16-.39a1.68,1.68,0,0,0-.92-2.18L7.53,13a1.67,1.67,0,0,0-2.18.92l-.16.38a1.69,1.69,0,0,0,.92,2.18Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M18.84,17.58a.5.5,0,0,1-.2-1,1.66,1.66,0,0,0,.87-2.1l-.14-.39a1.66,1.66,0,0,0-2.15-1L12.53,14.9a.5.5,0,0,1-.64-.3.49.49,0,0,1,.29-.64l4.69-1.75a2.68,2.68,0,0,1,3.44,1.57l.14.39a2.68,2.68,0,0,1-1.4,3.37A.52.52,0,0,1,18.84,17.58Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M6.82,21.92a2.66,2.66,0,0,1-1.1-.24,2.63,2.63,0,0,1-1.4-1.49l-.14-.39a2.67,2.67,0,0,1,1.41-3.37.5.5,0,0,1,.66.25.5.5,0,0,1-.25.66,1.67,1.67,0,0,0-.88,2.11l.14.39a1.69,1.69,0,0,0,.87.93,1.67,1.67,0,0,0,1.28.05l4.69-1.75a.5.5,0,1,1,.35.93L7.76,21.75A2.52,2.52,0,0,1,6.82,21.92Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M12.32,12.25a4.16,4.16,0,0,1-3.15-1.51,4.64,4.64,0,0,1-.93-3.86c.53-3,2.64-5.19,5-5.19h.6l-.11.6c-.28,1.47-.1,2.17.16,2.33s.78,0,1.12-.28l.31-.23.3.23c.54.42.79,1.26.79,2.63C16.42,9.88,14.58,12.25,12.32,12.25Zm.33-9.49c-1.45.32-3,1.78-3.43,4.3a3.6,3.6,0,0,0,.72,3,3.17,3.17,0,0,0,2.38,1.15c1.71,0,3.1-1.92,3.1-4.28A4.11,4.11,0,0,0,15.2,5.4a1.87,1.87,0,0,1-1.82.07C12.72,5.06,12.48,4.17,12.65,2.76Z"
    />
  </>
);

export const DownArrow = ({ classKey }) => (
  <>
    <path
      className={`cls--${classKey}`}
      d="M12,18.49a1,1,0,0,1-.69-.28L3.88,10.77a1,1,0,0,1,.69-1.65H19.43a1,1,0,0,1,.69,1.65l-7.43,7.44A1,1,0,0,1,12,18.49Zm7.38-8.39-14.81,0L12,17.5Zm0,0,.36.35-.36-.35Z"
    />
  </>
);

export const Download = ({ classKey }) => (
  <>
    <path
      className={`cls--${classKey}`}
      d="M12,19.28a.47.47,0,0,1-.35-.15l-5.31-5.3a.5.5,0,0,1,0-.71.48.48,0,0,1,.7,0l5,5,5-5a.48.48,0,0,1,.7,0,.5.5,0,0,1,0,.71l-5.31,5.3A.47.47,0,0,1,12,19.28Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M12,19.28a.5.5,0,0,1-.5-.5V4.49a.5.5,0,0,1,1,0V18.78A.5.5,0,0,1,12,19.28Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M19.77,21.62H4.24a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H19.77a.5.5,0,0,1,.5.5A.51.51,0,0,1,19.77,21.62Z"
    />
  </>
);

export const EditPen = ({ classKey }) => (
  <>
    <path
      className={`cls--${classKey}`}
      d="M2.66,21.75a.5.5,0,0,1-.48-.66l1.57-4.56a.62.62,0,0,1,.12-.19L18,2.21a.51.51,0,0,1,.71,0l3,3a.5.5,0,0,1,0,.7L7.57,20a.44.44,0,0,1-.19.12L2.82,21.72A.45.45,0,0,1,2.66,21.75Zm2-4.79-1.2,3.48,3.48-1.2L20.64,5.55,18.36,3.27Z"
    />
    <rect
      className={`cls--${classKey}`}
      x="17"
      y="4.29"
      width="1"
      height="4.22"
      transform="translate(0.6 14.25) rotate(-45)"
    />
    <rect
      className={`cls--${classKey}`}
      x="5.22"
      y="16.07"
      width="1"
      height="4.22"
      transform="translate(-11.18 9.37) rotate(-45)"
    />
  </>
);

export const File = ({ classKey }) => (
  <>
    <path
      className={`cls--${classKey}`}
      d="M18.79,22H6.21a.5.5,0,0,1-.5-.5V3.54a.5.5,0,0,1,.5-.5H12.5a.47.47,0,0,1,.35.15l6.29,6.29a.47.47,0,0,1,.15.35V21.46A.5.5,0,0,1,18.79,22ZM6.71,21H18.29V10l-6-6H6.71Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M18.79,10.33H12.5a.5.5,0,0,1-.5-.5V3.54h1V9.33h5.79Z"
    />
  </>
);

export const Gear = ({ classKey }) => (
  <>
    <path
      className={`cls--${classKey}`}
      d="M12,15.55a3.75,3.75,0,1,1,3.75-3.74A3.75,3.75,0,0,1,12,15.55Zm0-6.49a2.75,2.75,0,1,0,2.75,2.75A2.75,2.75,0,0,0,12,9.06Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M14.24,21.41l-1.76-1.94h-1L9.75,21.41l-4-2,.47-2.57-.42-.53-2.62-.12-1-4.35,2.28-1.29.18-.8L3.06,7.65,5.81,4.12l2.43,1L9,4.72,9.76,2.2h4.48L15,4.72l.8.39,2.43-1,2.75,3.53L19.4,9.77l.18.8,2.28,1.29-1,4.35-2.62.12-.42.53.47,2.57ZM6.86,18.87l2.66,1.31,1.56-1.71h1.84l1.56,1.71,2.66-1.31-.41-2.28,1-1.24L20,15.24l.69-2.88-2-1.14-.37-1.68,1.36-1.87L17.86,5.33l-2.14.87-1.6-.78L13.48,3.2h-3L9.88,5.42l-1.6.78L6.14,5.33,4.31,7.67,5.67,9.54,5.3,11.22l-2,1.14L4,15.24l2.32.11,1,1.24Z"
    />
  </>
);

export const MapPoint = ({ classKey }) => (
  <>
    <path
      className={`cls--${classKey}`}
      d="M12,17.79a.51.51,0,0,1-.39-.19C11.42,17.37,7,11.93,7,7.68a5,5,0,1,1,10,0c0,4.25-4.42,9.69-4.6,9.92A.51.51,0,0,1,12,17.79Zm0-14.1a4,4,0,0,0-4,4c0,3.2,2.92,7.37,4,8.79,1.07-1.42,4-5.6,4-8.79A4,4,0,0,0,12,3.69Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M12,9.68a2,2,0,1,1,2-2A2,2,0,0,1,12,9.68Zm0-3a1,1,0,1,0,1,1A1,1,0,0,0,12,6.68Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M22.25,20.89H1.85a.48.48,0,0,1-.42-.23.47.47,0,0,1,0-.47l3.49-7.67a.5.5,0,0,1,.46-.29h2a.5.5,0,0,1,.5.5.51.51,0,0,1-.5.5H5.67l-3,6.66H21.47l-3-6.66H16.75a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.46.29l3.49,7.67a.47.47,0,0,1,0,.47A.48.48,0,0,1,22.25,20.89Z"
    />
  </>
);

export const Signpost = ({ classKey }) => (
  <>
    <path
      className={`cls--${classKey}`}
      d="M17.64,7.58h-9v-4h9l1.83,2Zm-8-1H17.2l.92-1-.92-1H9.69Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M17.64,17.43h-9v-4h9l1.83,2Zm-8-1H17.2l.92-1-.92-1H9.69Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M13.94,12.51H5l-1.83-2L5,8.49h9Zm-8.51-1h7.51v-2H5.43l-.92,1Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M12.94,22.31H9.69V16.43h3.25Zm-2.25-1h1.25V17.43H10.69Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M10.19,14.42a.5.5,0,0,1-.5-.5V12a.5.5,0,0,1,1,0v1.92A.5.5,0,0,1,10.19,14.42Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M10.19,9.5a.5.5,0,0,1-.5-.5V7.08a.5.5,0,0,1,1,0V9A.5.5,0,0,1,10.19,9.5Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M12.44,9.5a.5.5,0,0,1-.5-.5V7.08a.5.5,0,0,1,1,0V9A.5.5,0,0,1,12.44,9.5Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M12.44,14.42a.5.5,0,0,1-.5-.5V12a.5.5,0,0,1,1,0v1.92A.5.5,0,0,1,12.44,14.42Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M12.94,4.57H9.69V3.32a1.63,1.63,0,1,1,3.25,0Zm-2.25-1h1.25V3.32a.63.63,0,1,0-1.25,0Z"
    />
  </>
);

export const Search = ({ classKey }) => (
  <>
    <path
      className={`cls--${classKey}`}
      d="M15.05,1.47A7.47,7.47,0,0,0,8.76,13L7.53,14.21l-.36-.36a.5.5,0,0,0-.71,0L1.13,19.18a.48.48,0,0,0,0,.7l3,3a.48.48,0,0,0,.7,0l5.33-5.33a.5.5,0,0,0,0-.71l-.36-.36L11,15.24a7.47,7.47,0,1,0,4-13.77ZM4.47,21.81,2.19,19.53l4.62-4.62,2.28,2.28ZM9.1,15.75l-.85-.85,1.11-1.12a8.73,8.73,0,0,0,.86.86Zm6-.33A6.48,6.48,0,1,1,21.53,9,6.47,6.47,0,0,1,15.05,15.42Z"
    />
    <path
      className={`cls--${classKey}`}
      d="M15.05,3.56A5.4,5.4,0,0,0,9.67,9a.5.5,0,0,0,1,0,4.39,4.39,0,0,1,4.38-4.39.5.5,0,0,0,.5-.5A.5.5,0,0,0,15.05,3.56Z"
    />
  </>
);
