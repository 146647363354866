import React from "react";

export default props => {
  if (!props.editing) {
    return viewing(props);
  }
  return editing(props);
};

function editing({ entity, propName }) {
  // TODO : Finish this editing image component
  return <img className="image" src={entity[propName]} alt={propName} />;
}

function viewing({ entity, propName }) {
  let src = entity[propName];
  if (src) {
    return <img className="image" src={src} alt={propName} />;
  }
  return null;
}
