/**
 * # Schema for the section entity.
 *
 */

export default {
  name: "Sections",
  nameSingular: "Section",
  showInNav: true,
  index: 0,
  route: "sections",
  type: "sections",
  urlList: "trail/trailsections",
  url: "trail/trailsection",
  fields: {
    startTitle: {
      title: "Start Location",
      tab: "Details",
      editingGroup: "Information",
      showViewState: false,
      component: "Input"
    },
    endTitle: {
      title: "End Location",
      tab: "Details",
      editingGroup: "Information",
      showViewState: false,
      component: "Input"
    },
    segment: {
      title: "Segment",
      tab: "Details",
      editingGroup: "Information",
      component: "EntityLink:segments",
      placeholder: "Select segment"
    },
    mainTrailDistance: {
      title: "Main Trail",
      tab: "Details",
      editingGroup: "Information",
      component: "Input:number",
      placeholder: "Enter Miles",
      postfix: "Miles"
    },
    spurTrailDistance: {
      title: "Spur Trail",
      tab: "Details",
      editingGroup: "Information",
      component: "Input:number",
      placeholder: "Enter Miles",
      postfix: "Miles"
    },
    description: {
      title: "Description",
      tab: "Details",
      component: "TextArea",
      placeholder: "Write a description..."
    },
    wormholes: {
      title: "Wormholes",
      tab: "Details",
      component: "TextArea",
      placeholder: "Tell me about the wormholes..."
    },
    map: {
      tab: "Details",
      placement: "right",
      component: "SingleImage"
    },
    photos: {
      tab: "Details",
      component: "ImageGrid"
    },
    projects: {
      tab: "Projects",
      component: "EntityList:projects"
    },
    trailheads: {
      tab: "Trailheads",
      component: "EntityList:trailheads"
    },
    campsites: {
      tab: "Campsites",
      component: "EntityList:campsites"
    },
    assets: {
      tab: "Assets",
      component: "EntityList:assets"
    },
    people: {
      tab: "People",
      component: "EntityList:people"
    },
    files: {
      tab: "Files",
      component: "EntityList:fileLinks"
    },
    notes: {
      tab: "Notes",
      component: "Notes"
    }
  },
  tabOrder: [
    "Details",
    "Projects",
    "Trailheads",
    "Campsites",
    "Assets",
    "People",
    "Files",
    "Notes"
  ],
  startingFields: ["startTitle", "endTitle", "segment"],
  title: "{{startTitle}} - {{endTitle}}",
  subtitle: "{{mainTrailDistance}} Miles",
  groupBy: {
    propName: "segment.name",
    title: "{{groupedItemValue}}",
    map: {
      undefined: "Not Connected to a Segment"
    },
    orderMap: {
      "MN/WI Border to Duluth": 0,
      "Duluth to Two Harbors": 1,
      "Two Harbors to Silver Bay": 2,
      "Silver Bay to Caribou Falls Wayside": 3,
      "Caribou Falls Wayside to Lutsen": 4,
      "Lutsen to Grand Marais": 5,
      "Grand Marais to 270 Degree Overlook": 6
    },
    subtitle: "{{groupedItemCount}} sections"
  },
  jsonObjectName: "trail_section_data"
};
