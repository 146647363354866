import {
    Auth
} from "aws-amplify";
import {
    get,
    post,
    remove,
    put
} from "./AxiosCommon";
import schema from "../data/schema";
import {
    localTesting,
    objectToArray
} from "../util/utilFunctions";

const HTTP = {
    post: post,
    get: get,
    remove: remove,
    put: put
}

if (localTesting()) {
    let localLoad = require("./LocalLoader").default
    HTTP.post = localLoad.post
    HTTP.get = localLoad.get
    HTTP.remove = localLoad.remove
    HTTP.put = localLoad.put
}

class API {
    getAuth(shtaType, func) {
        let schemaItem = schema[shtaType];
        if (localTesting()) {
            return func(schemaItem, {})
        }
        return Auth.currentSession().then((response) => {

            let headers = {
                headers: {
                    Authorization: response.idToken.jwtToken
                }
            };
            return func(schemaItem, headers);
        });
    }

    create(shtaType, shtaObject) {
        delete shtaObject.id
        return this.getAuth(shtaType, (schemaItem, headers) => {
            const processor = getDataProcessor(schemaItem, true);
            return HTTP.post(`${schemaItem.url}`, shtaObject, headers).then(processor)
        });
    }

    list(shtaType, idSubset) {
        if (idSubset && idSubset.length === 0) {
            return Promise.resolve([])
        }
        let subset = idSubset ? `?id=${idSubset.join(",")}` : "";

        return this.getAuth(shtaType, (schemaItem, headers) => {
            // the subset list uses the main url rather than the list url
            let requestUrl = subset ? `${schemaItem.url}${subset}` : schemaItem.urlList
            const processor = getDataProcessor(schemaItem, false);
            return HTTP.get(requestUrl, {}, headers)
                .then(processor);
        });
    }

    remove(shtaType, id) {
        return this.getAuth(shtaType, (schemaItem, headers) => {
            return HTTP.remove(`${schemaItem.url}/${id}`, {}, headers);
        });
    }

    retrieve(shtaType, id) {
        return this.getAuth(shtaType, (schemaItem, headers) => {
            const processor = getDataProcessor(schemaItem, true)
            return HTTP.get(`${schemaItem.url}?id=${id}`, {}, headers)
                .then(processor);
        });
    }

    update(shtaType, updateObj) {

        return this.getAuth(shtaType, (schemaItem, headers) => {
            const processor = getDataProcessor(schemaItem, true);
            return HTTP.put(
                `${schemaItem.url}`,
                getUpdateObjectForSchema(schemaItem, updateObj),
                headers
            ).then(processor)
        });
    }
}

function getUpdateObjectForSchema(schemaItem, {
    id,
    ...objectProps
}) {
    return objectToArray(objectProps).reduce((agg, kv) => {
        if (typeof kv.value === 'function') {
            // Remove functions from our objects to ensure serializability.
            return agg
        }
        agg[schemaItem.jsonObjectName][kv.key] = kv.value
        return agg
    }, {
        id: id,
        [schemaItem.jsonObjectName]: {}
    })
}

/**
 * Gets the data processor to convert responses into what the application expects
 *
 * @param {{jsonObjectName: string}} schemaItem
 * @param {boolean} isSingleValue - indicates that we should convert arrays to singular
 * @returns {(data: any) => any}
 */
function getDataProcessor(schemaItem, isSingleValue) {
    const converter = getConverter(schemaItem);
    return ({
        data
    }) => {
        if (!data) {
            return {
                data: null
            }
        }
        if (!Array.isArray(data)) {
            return {
                data: converter(data)
            };
        }
        let converted = data.map(converter);
        if (isSingleValue) {
            converted = converted[0];
        }
        return {
            data: converted
        };
    }
}

/**
 * Gets the correct converter for the schema
 *
 * @param {{jsonObjectName: string}} schemaItem
 * @returns
 */
function getConverter(schemaItem) {
    return (item) => {
        if (item[schemaItem.jsonObjectName]) {
            return {
                id: item.id,
                ...item[schemaItem.jsonObjectName]
            }
        }
        return item;
    }
}

export default API