import React, { Component } from "react";
import { Headline3, Eyebrow } from "../atoms/Typeography";
import Card from "../atoms/Card";
import Button, { BUTTON_TYPE } from "../atoms/Button";
import { getComponentFromString } from "../componentMap";

import "./AddEntity.css";
import ShtaApi from "../../api/ShtaApi";
import { AppContext } from "../../AppContext";
import {
  objectToArray,
  populateStringFromScope
} from "../../util/utilFunctions";
import LoadIndicator from "../atoms/LoadIndicator";

class AddEntity extends Component {
  constructor(props) {
    super(props);
    this.api = new ShtaApi();
    let newEntity = this.buildNewEntity();
    this.state = {
      disabled: true,
      saving: false,
      newEntity: this.addOverrides(newEntity)
    };
  }

  addOverrides(newEntity) {
    let { overrides } = this.props;
    if (!overrides) {
      return newEntity;
    }
    return {
      ...newEntity,
      ...overrides
    };
  }

  buildNewEntity() {
    let { parentEntity, schemaItem } = this.props;
    if (!parentEntity || typeof parentEntity.getSchema !== "function") {
      return {};
    }
    let parentSchema = parentEntity.getSchema();
    // Generating the correct fields.
    return objectToArray(schemaItem.fields).reduce((obj, kvNext) => {
      const { key, value } = kvNext;
      if (value.component === "DateCreated") {
        obj[key] = Date.now();
        return obj;
      }

      let [compName, compType] = value.component.split(":");
      if (compName !== "EntityLink") {
        return obj;
      }
      if (shouldPopulateParentType(compType, parentSchema)) {
        obj[key] = {
          id: parentEntity.id,
          name: populateStringFromScope(parentSchema.title, parentEntity),
          type: parentSchema.type
        };
        return obj;
      }
      let field = objectToArray(parentSchema.fields).filter(
        kv => kv.value.component === `EntityLink:${compType}`
      )[0];
      if (field) {
        let val = parentEntity[field.key];
        obj[key] = typeof val === "object" ? val : null;
      }
      return obj;
    }, {});
  }

  handleChange(field, value) {
    let newEntity = {
      ...this.state.newEntity,
      [field.propName]: value
    };
    let disabled = !this.props.schemaItem.startingFields.reduce(
      (allPopulated, nextFieldName) => {
        return allPopulated && newEntity[nextFieldName];
      },
      true
    );
    this.setState({
      newEntity: newEntity,
      disabled: disabled
    });
  }

  saveNewItem(appContext) {
    // Disable button
    this.setState({
      disabled: true,
      saving: true
    });
    let { route, type } = this.props.schemaItem;
    this.api
      .create(type, this.state.newEntity)
      .then(val => {
        return this.props.onAdded(val.data).then(() => val.data);
      })
      .then(newObj => {
        if (this.props.schemaItem.navigateOnCreate === false) {
          return;
        }
        if (!appContext.history) {
          window.location.reload();
          return;
        }
        appContext.history.push(`/${route}/${newObj.id}/`);
      });
  }

  render() {
    let { startingFields, nameSingular, fields } = this.props.schemaItem;
    return (
      <AppContext.Consumer>
        {appContext => (
          <form>
            <Card
              padding={true}
              className="add-entity__card"
              onCancel={this.props.onCancel}
            >
              <div className="with--h-margin-2 with--t-margin-3">
                <Headline3>{`Add ${nameSingular}`}</Headline3>
                {/* here we iterate through starting fields on entity */}
                {startingFields
                  .map(fieldName => {
                    return { ...fields[fieldName], propName: fieldName };
                  })
                  .map(field => {
                    let [componentName, compType] = field.component.split(":");
                    let Comp = getComponentFromString(componentName);
                    return (
                      <div key={field.title}>
                        <Eyebrow className="with--margin-0 with--bold-weight">
                          {field.title}
                        </Eyebrow>
                        <Comp
                          value={this.state.newEntity[field.propName]}
                          hideLabel={true}
                          componentType={compType}
                          name={field.title}
                          editing={true}
                          placeholder={field.placeholder}
                          options={field.options}
                          onChange={val => this.handleChange(field, val)}
                          id={"new" + field.propName}
                        />
                      </div>
                    );
                  })}
                <div className="add-entity__button-container">
                  {this.state.saving ? (
                    <LoadIndicator size={12} />
                  ) : (
                    <>
                      <Button
                        buttonType={BUTTON_TYPE.secondary}
                        onClick={this.props.onCancel}
                      >
                        cancel
                      </Button>
                      <Button
                        buttonType={BUTTON_TYPE.primary}
                        disabled={this.state.disabled}
                        onClick={() => this.saveNewItem(appContext)}
                      >
                        {`Add ${nameSingular}`}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Card>
          </form>
        )}
      </AppContext.Consumer>
    );
  }
}

function shouldPopulateParentType(compType, parentSchema) {
  if (parentSchema.type === compType) {
    return true;
  }
  if (compType.indexOf("{{parentType[") === -1) {
    return false;
  }

  let parentTypeOps = compType
    .slice(13, -2) // remove parentType anotation
    .split(","); // get types as array.

  return parentTypeOps.indexOf(parentSchema.type) > 1;
}

export default AddEntity;
