import React from "react";

import "./LoadIndicator.css"
import { numberToPixels } from "../../util/utilFunctions";

const LoadIndicator = ({size = 20}) => {
  let style = {width: numberToPixels(size), height: numberToPixels(size)}
  return (
    <div className="spinner"> 
      <div className="bounce1" style={style}></div>
      <div className="bounce2" style={style}></div>
      <div className="bounce3" style={style}></div>
  </div>
  )
}

//  style={{margin: numberToPixels(margin)}}>
export default LoadIndicator