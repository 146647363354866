/**
 * # Schema for the Trailhead entity.
 *
 */

export default {
    name: "Trailheads",
    nameSingular: "Trailhead",
    showInNav: true,
    index: 3,
    route: "trailheads",
    type: "trailheads",
    urlList: "trail/trailheads",
    url: "trail/trailhead",
    fields: {
        name: {
            title: "Trailhead Name",
            tab: "Details",
            editingGroup: "Information",
            showViewState: false,
            component: "Input"
        },
        locationMiles: {
            title: "Trail Mile Marker",
            tab: "Details",
            editingGroup: "Information",
            showViewState: false,
            component: "Input",
            postfix: "Miles"
        },
        segment: {
            title: "Segment",
            tab: "Details",
            editingGroup: "Information",
            component: "EntityLink:segments",
            placeholder: "Select segment"
        },
        section: {
            title: "Section",
            tab: "Details",
            editingGroup: "Information",
            component: "EntityLink:sections",
            placeholder: "Select section"
        },
        capacity: {
            title: "Capacity",
            tab: "Details",
            postfix: "Cars",
            editingGroup: "Information",
            component: "Input:number",
            placeholder: "Enter max vehicle quantity"
        },
        plowed: {
            title: "Plowed",
            tab: "Details",
            editingGroup: "Information",
            component: "Dropdown",
            options: ["Plowed", "UnPlowed", "Inconsistently Plowed"]
        },
        description: {
            title: "Description",
            tab: "Details",
            component: "TextArea",
            placeholder: "Write a description..."
        },
        facilities: {
            title: "Facilities",
            tab: "Details",
            component: "TextArea",
            placeholder: "Tell me about the facilities..."
        },
        signage: {
            title: "Trailhead Sign",
            tab: "Details",
            component: "TextArea",
            placeholder: "Tell me about the sign..."
        },
        map: {
            tab: "Details",
            placement: "right",
            component: "SingleImage"
        },
        photos: {
            tab: "Details",
            component: "ImageGrid"
        },
        projects: {
            tab: "Projects",
            component: "EntityList:projects"
        },
        people: {
            tab: "People",
            component: "EntityList:people"
        },
        files: {
            tab: "Files",
            component: "EntityList:fileLinks"
        },
        notes: {
            tab: "Notes",
            component: "Notes"
        }
    },
    tabOrder: [
        "Details",
        "Projects",
        "People",
        "Files",
        "Notes"
    ],
    startingFields: ["name", "capacity"],
    jsonObjectName: "trailhead_data",
    title: "{{name}}",
    subtitle: "Mile {{locationMiles|0}}"
};