import React from "react";
import AsyncSelect from "react-select/lib/Async";
import { Link } from "react-router-dom";

import "./Dropdown.css";
import { ListType } from "../atoms/Typeography";
import ShtaApi from "../../api/ShtaApi";
import schema from "../../data/schema";
import { populateStringFromScope } from "../../util/utilFunctions";

export default props => {
  if (props.editing) {
    return <DropdownEditing {...props} />;
  }
  if (props.showViewState) {
    return viewing(props);
  }
  return null;
};

class DropdownEditing extends React.Component {
  constructor(props) {
    super(props);

    // type of the mapped component
    if (this.props.componentType === "{{parentType}") {
      this.linkedSchemaItem = this.props.parentSchema;
    } else {
      this.linkedSchemaItem = schema[this.props.componentType];
    }

    this.api = new ShtaApi();
    this.state = {
      loading: true,
      selectedOption: this.preselectedToOption(this.props.value)
    };
    this.list = this.loadList();
  }

  preselectedToOption(val) {
    if (!val) {
      return null;
    }
    return {
      value: val,
      label: val.name
    };
  }

  entityToOption(val) {
    let computedTitle = populateStringFromScope(
      this.linkedSchemaItem.title,
      val
    );
    return {
      value: {
        id: val.id,
        name: computedTitle,
        type: this.linkedSchemaItem.type
      },
      label: computedTitle
    };
  }

  onChange(updatedValue) {
    this.setState({
      selectedOption: updatedValue
    });
    this.props.onChange(updatedValue.value);
  }

  loadList() {
    let { componentType } = this.props;
    if (componentType.indexOf("{{parentType") > -1) {
      return Promise.resolve([]);
    }
    return this.api.list(componentType).then(val => {
      this.setState({ loading: false });
      return (val.data || []).map(item => this.entityToOption(item));
    });
  }

  loadOptions(inputValue) {
    return this.list.then(list => {
      let filtered = list;
      if (inputValue) {
        let text = inputValue.toLowerCase();
        filtered = list.filter(
          item => item.label.toLowerCase().indexOf(text) > -1
        );
      }
      return filtered;
    });
  }

  render() {
    const { selectedOption } = this.state;
    return (
      <div className="flx flx--row input__container">
        {this.props.hideLabel ? null : (
          <label
            className="txt txt--list-type input__label--editing"
            htmlFor={this.props.id}
          >
            {this.props.name}
          </label>
        )}

        <AsyncSelect
          id={this.props.id}
          className="select"
          classNamePrefix="select"
          value={selectedOption}
          isDisabled={false}
          isLoading={this.state.loading}
          isClearable={false}
          isSearchable={true}
          placeholder={this.props.placeholder}
          onChange={val => this.onChange(val)}
          name={this.props.name}
          cacheOptions
          defaultOptions
          loadOptions={inputValue => this.loadOptions(inputValue)}
        />
      </div>
    );
  }
}

function viewing(props) {
  return (
    <div className="flx flx--row input__container">
      {props.hideLabel ? null : (
        <ListType className="input__label-title">{props.name}:</ListType>
      )}
      {props.value && props.value.name ? (
        <Link
          to={"/" + [props.value.type, props.value.id].join("/")}
          className="txt with--margin-0 with--bold-weight with--color-primary with--no-decoration"
        >
          {props.value.name}
        </Link>
      ) : (
        <p className="txt with--margin-0 txt--medium-2" onClick={props.onClick}>
          {props.placeholder}
        </p>
      )}
    </div>
  );
}
