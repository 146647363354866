import React from "react";
import ReactDOM from "react-dom";
import 'dotenv/config'
import { UnauthApp, AuthApp } from "./App";
import * as serviceWorker from "./serviceWorker";
import { localTesting } from "./util/utilFunctions";

import "./index.css";

if (localTesting()) {
  ReactDOM.render(<UnauthApp />, document.getElementById("root"));
} else {
  ReactDOM.render(<AuthApp />, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
