import React from "react";
import { Headline3, ListType, Eyebrow, Headline1 } from "../../atoms/Typeography";
import CircleImage from "../CircleImage";
import { populateStringFromScope } from "../../../util/utilFunctions";
import Icon from "../../atoms/Icon";

export const SectionTitle = ({entity}) => {
  let distance = (entity.mainTrailDistance || 0) + (entity.spurTrailDistance || 0)
  return (
    <>
      <Eyebrow className='entity-title__sub-header txt--light'>{distance + " miles"}</Eyebrow>
      <Headline1 className='entity-title__header txt--light'>
        <p className='with--margin-0'>{entity.startTitle}</p>
        <span>to </span>
        <span className='with--margin-0 with--normal-weight'>{entity.endTitle}</span>
      </Headline1>
    </>
  )
}

export const PersonTitle = ({ entity }) => {
  return (
      <div className="flx flx--row">
        <CircleImage
          src={entity.photoLink}
          fallbackName={entity.firstName || ""}
          size="5em"
        />
        <div className="flx flx--column flx--center">
          <Headline3 className="with--margin-0">
            {entity.firstName} {entity.lastName}
          </Headline3>
          <ListType className="with--margin-0 with--bold-weight">{entity.title}</ListType>
        </div>
      </div>
  );
}

export const DefaultTitle = ({entity, schemaItem}) => {
  return (
    <>
      <div className="flx flx--row">
        <Icon name={schemaItem.type} className="entity-title__icon" color="#FFF"/>
        <Eyebrow className='entity-title__sub-header txt--light'>{populateStringFromScope(schemaItem.subtitle, entity)}</Eyebrow>
      </div> 
      <Headline1 className='entity-title__header txt--light'>{populateStringFromScope(schemaItem.title, entity)}</Headline1>
    </>
  )
}
