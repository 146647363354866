import React from "react";
import { propHasValue } from "../../util/utilFunctions";
import EntityListPage from "./EntityListPage";
import EntityPage from "./EntityPage";
import { AppContext } from "../../AppContext";

export default function Content(props) {
  return (
    <AppContext.Consumer>
      {appContext => (
        <AppContext.Provider value={{ ...appContext, history: props.history }}>
          <main className="main__content">
            {propHasValue(props, "match.params.id") ? (
              <EntityPage {...props} />
            ) : (
              <EntityListPage {...props} />
            )}
          </main>
        </AppContext.Provider>
      )}
    </AppContext.Consumer>
  );
}
