import React from "react"
import ListItemCard from "../molecules/ListCards/ListItemCard";
import Add from "../atoms/Add";
import { Headline3, Eyebrow } from "../atoms/Typeography";

import { getNestedValue, groupBy } from "../../util/utilFunctions";

/**
 * Gets grouped title
 *
 * @param {{itle?: string, subtitle?: string, groupBy?: {}}} props
 * @returns {JSX}
 */
const GroupedTitle = ({ title, subtitle }) => {
  return (
    <div className="flx flx--column">
      {title ? (
        <Headline3 className="with--l-margin-1 with--b-margin-0">
          {title}
        </Headline3>
      ) : null}
      {subtitle ? (
        <Eyebrow className="with--l-margin-2 with--v-margin-1">
          {subtitle}
        </Eyebrow>
      ) : null}
    </div>
  );
}

/**
 * Entity Add
 * 
 * @param {object} props
 * @param {string} props.title
 * 
 * @returns {JSX}
 */
const EntityAdd = (props) => {
  let { schemaItem, parentEntity, listData, onNewItem, match, ...otherProps } = props;

  return (
    <Add
      {...otherProps}
      schemaItem={schemaItem}
      parentEntity={parentEntity}
      onAdded={(item) => onNewItem(item)}
      currentItems={listData.map(({ id }) => id)}
      match={match}
    />
  );
}


/**
 *
 * @param {object} props
 * @param {number} props.index
 * @param {{key: string, index?: any, list: any[], title?: string, subtitle?: string, groupBy?: {}, groupedItemValue: string}} props.item
 * @param {number} index
 * @returns {JSX}
 */
const GroupedAdd = (props)  => {
  let { schemaItem, parentEntity, index, item } = props;
  let shouldShow = (parentEntity || schemaItem.allowGlobalAdd) && index === 0;
  let groupAddName = getNestedValue(
    item,
    `groupBy.groupedAdd.${item.groupedItemValue}`,
    {}
  );

  return shouldShow || groupAddName.title
    ? ( 
      <EntityAdd 
        {...props} 
        title={groupAddName.title || schemaItem.nameSingular} 
        overrides={groupAddName.overrides} 
      />
    )
    : null;
}

export default function GroupedEntityList(props) {
  let { schemaItem, parentEntity, listData, regroup, onDeleteItem } = props;

  let listClass = schemaItem.oneColumnList ? "one--col" : " two--col";

  if (
    !listData.length &&
    (parentEntity || schemaItem.allowGlobalAdd)
  ) {
    return (
      <div className="flx flx--column" key={"noValues"}>
        <div className="flx flx--row with--t-margin-2 flx--space-between">
          <div className="flx flx--column" />
          <EntityAdd {...props} title={schemaItem.name} />
        </div>
      </div>
    );
  }

  const List = groupBy(listData, schemaItem)
    .sort((a, b) => a.index - b.index)
    .map((item, index) => {
      return (
        <div className="flx flx--column" key={"parent-" + item.key}>
          <div className="flx flx--row with--t-margin-2 flx--space-between">
            <GroupedTitle title={item.title} subtitle={item.subtitle} />
            <GroupedAdd {...props} item={item} index={index} />
          </div>
          <div className={`flx--grid grid--margin ${listClass}`}>
            {item.list
              .sort((a, b) => a.id - b.id)
              .map((data) => (
                <ListItemCard
                  key={data.id}
                  entity={data}
                  schemaItem={schemaItem}
                  regroup={() => regroup()}
                  onDeleteChildListItem={
                    parentEntity
                      ? (item) => onDeleteItem(item)
                      : null
                  }
                />
              ))}
          </div>
        </div>
      );
    });
  return (
    // <>
    <div className="flx flx--column">
      {List}
    </div>
    // </>
  )
}
