import React from "react";
import schema from "../../data/schema";
import EntityList from "./EntityList";
import { objectToArray } from "../../util/utilFunctions";

const EntityTabContent = ({ tabName, entity, schemaItem, ...others }) => {
  let field = objectToArray(schemaItem.fields).filter(
    kv => kv.value.tab === tabName
  )[0];
  if (!field) {
    throw new Error("Fix the schema: there is no field for the tab: " + tabName);
  }
  let ids = entity[field.key];

  let [, compPropName] = field.value.component.split(":");

  let listSchemaItem = schema[compPropName];
  return (
    <div className="with--scroll">
      <EntityList
        parentEntityPropertyName={field.key}
        parentEntity={entity}
        itemIds={ids || []}
        schemaItem={listSchemaItem}
        {...others}
      />
    </div>
  );
};

export default EntityTabContent;
