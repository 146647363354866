import React from "react";
import { Eyebrow, Headline3 } from "../../atoms/Typeography";
import Card from "../../atoms/Card";
import { populateStringFromScope } from "../../../util/utilFunctions";
import Icon from "../../atoms/Icon";

export default function TemplateCard(props) {
  // Add link here too.
  return (
    <Card padding={true}>
       <TemplateCardContent {...props} />
    </Card>
  );
}

export const TemplateCardContent = ({ entity, schemaItem }) => {
  return (
    <div className="flx flx--row">
      <Icon className="size--24 with--r-margin-2 with--v-margin-auto" color="#000" name={schemaItem.type} />
      <div className="flx flx--column">
        <Eyebrow className="entity-title__sub-header txt--medium">
          {populateStringFromScope(schemaItem.subtitle, entity)}
        </Eyebrow>
        <Headline3 className="entity-title__header">
          {populateStringFromScope(schemaItem.title, entity)}
        </Headline3>
      </div>
    </div>)
}

