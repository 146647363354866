/**
 * # Schema for the File entity.
 *
 */

export default {
  name: "File Links",
  nameSingular: "File Link",
  showInNav: false,
  route: "fileLinks",
  type: "fileLinks",
  urlList: "maint/fileLinks",
  url: "maint/fileLink",
  fields: {
    fileUrl: {
      title: "Url",
      placeholder: "Enter URL",
      component: "Input",
    },
    name: {
      title: "Name",
      placeholder: "Enter Name", 
      component: "Input",
    },
    fileType: {
      title: "File Type",
      showViewState: false,
      component: "Dropdown",
      options: [
        "PDF",
        "Word",
        "Excel",
        "Powerpoint",
        "Jpeg",
        "External Site"
      ]
    },
    added: {
      title: "Added",
      component: "DateCreated"
    }
  }, 
  startingFields: ["name", "fileUrl", "fileType"],
  listNoLink: true,
  navigateOnCreate: false,
  jsonObjectName: "file_link_data",
  allowGlobalAdd: true,
  title: "{{name}}",
  subtitle: "{{fileType}}",
  icon: "faFire"
};
