import React, { Component } from "react";
import AddEntity from "../molecules/AddEntity";
import BulkAddEntity from "./BulkAddEntity";

import "./Add.css";
import AddFeature from "./AddFeature";

const FullScreenOverlay = props => {
  return (
    <div className={props.showOverlay ? "full-screen__overlay" : "hidden"}>
      {props.children}
    </div>
  );
};

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      showingBulkAdd: false,
      showingSingleAdd: false
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  eventHandler() {
    this.setState(prevState => ({
      toggle: !prevState.toggle
    }));
  }

  togglePopup() {
    this.setState(prevState => ({
      showPopup: !prevState.showPopup
    }));
  }

  onAdded(item) {
    if (!this.props.parentEntity) {
      this.setState({ showPopup: false });
      return Promise.resolve(item);
    }
    return this.props.onAdded(item).then(() => {
      this._isMounted && this.setState({ showPopup: false });
      return item;
    });
  }

  renderAddPopup() {
    const { schemaItem, currentItems } = this.props;
    if (schemaItem.allowBulkAdd && this.props.parentEntity) {
      return (
        <BulkAddEntity
          schemaItem={schemaItem}
          onAdded={items => this.onAdded(items)}
          onCancel={() => this.togglePopup()}
          currentItems={currentItems || []}
        />
      );
    }
    return (
      <AddEntity
        {...this.props}
        onAdded={item => this.onAdded(item)}
        onCancel={() => this.togglePopup()}
      />
    );
  }

  render() {
    return (
      <>
        <div className="flx flx--one flx--row flx--end width--100">
          <AddFeature
            title={this.props.title}
            addHandler={() => this.togglePopup()}
          />
        </div>
        <FullScreenOverlay showOverlay={this.state.showPopup}>
          <div className="add-card__container">
            {this.state.showPopup && this.renderAddPopup()}
          </div>
        </FullScreenOverlay>
      </>
    );
  }
}

export default Add;
