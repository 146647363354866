import React from "react";
import { Headline3, ListType } from "../../atoms/Typeography";
import Card from "../../atoms/Card";
import CircleImage from "../CircleImage";

export default function PersonCard({ entity, onDeleteChildListItem }) {
  return (
    <Card
      padding={true}
      onCancel={
        onDeleteChildListItem
          ? e => {
              e.preventDefault();
              onDeleteChildListItem(entity);
            }
          : null
      }
    >
      <div className="flx flx--row">
        <CircleImage
          src={entity.photoLink}
          fallbackName={entity.firstName || ""}
          size="5em"
        />
        <div className="flx flx--column flx--center">
          <div className="flx flx--column flx--start">
            <Headline3 className="with--margin-0">
              {entity.firstName} {entity.lastName}
            </Headline3>
            <ListType className="with--margin-0 ">
              {entity.title || entity.personType}
            </ListType>
          </div>
        </div>
      </div>
    </Card>
  );
}
