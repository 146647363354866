import store from '../util/store'
import schema from "../data/schema";
import TestData from "../data/local/db.json"
import {
  objectToArray
} from '../util/utilFunctions';

const DB_KEY = "database"
let data = loadFromStorage(TestData)

const NETWORK_SIMULATION_TIME = 200;

class LocalAPI {
  post(url, newObj) {
    let {
      schemaItem
    } = getContextFromUrl(url)
    if (!data[schemaItem.type]) {
      data[schemaItem.type] = []
    }
    let obj = {
      id: data[schemaItem.type].reduce((a, n) => n.id > a ? n.id : a, 0) + 1,
      [schemaItem.jsonObjectName]: newObj
    }
    data[schemaItem.type].push(obj)
    save()
    return returnAsPromise(obj)
  }

  get(url) {
    let {
      schemaItem,
      ids,
    } = getContextFromUrl(url)

    let arr = data[schemaItem.type] || []
    if (ids) {
      arr = arr.filter((obj) => ids.indexOf(obj.id) > -1)
    }

    return returnAsPromise(arr);
  }

  put(url, obj) {
    let {
      schemaItem
    } = getContextFromUrl(url)

    data[schemaItem.type].forEach((next, index) => {
      if (next.id === obj.id) {
        data[schemaItem.type][index] = obj
      }
    })
    save()
    return returnAsPromise(obj);
  }

  remove(url) {
    let urlVals = url.split("/")
    let id = urlVals[urlVals.length - 1]

    let {
      schemaItem
    } = getContextFromUrl(url.replace(`/${id}`, ""))

    data[schemaItem.type] = data[schemaItem.type].filter((next) => next.id != id)
    save()
    return Promise.resolve();
  }
}

function getContextFromUrl(url) {
  let [baseUrl, ids] = url.split('?id=')
  let schemaItem = objectToArray(schema).filter(({
    value
  }) => {
    return value.urlList === baseUrl || value.url === baseUrl
  })[0]
  return {
    schemaItem: schemaItem.value,
    isListUrl: schemaItem.urlList === baseUrl,
    ids: ids ? ids.split(',').map(i => Number(i)) : undefined
  }
}

function returnAsPromise(value) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: value
      })
    }, NETWORK_SIMULATION_TIME)
  })
}


function save() {
  store.setItem(DB_KEY, data)
}

function loadFromStorage(testData) {
  let inProgress = store.getItem(DB_KEY)
  return inProgress || testData
}

const mockApi = new LocalAPI();

export default mockApi