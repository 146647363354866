import peopleSchema from "./schema/peopleSchema";
import sectionSchema from "./schema/sectionSchema";
import assetSchema from "./schema/assetSchema";
import trailheadSchema from "./schema/trailheadSchema";
import projectSchema from "./schema/projectSchema";
import campsiteSchema from "./schema/campsiteSchema";
import taskSchema from "./schema/taskSchema";
import segmentSchema from "./schema/segmentSchema";
import fileLinkSchema from "./schema/fileLinkSchema";
/**
 * # Schema for the data within the application.
 * **All data in the app will be based, and driven off of this schema.**
 *
 * Brief description of the parts of the schema and how it is used here.
 *
 */

const schema = {
  segments: segmentSchema,
  sections: sectionSchema,
  projects: projectSchema,
  campsites: campsiteSchema,
  assets: assetSchema,
  trailheads: trailheadSchema,
  tasks: taskSchema,
  people: peopleSchema,
  fileLinks: fileLinkSchema,
  // files: fileLinkSchema,
};

window["schema"] = schema;

export default schema;
