import React from "react";
import { BodyCopy } from "../atoms/Typeography";
import "./TextArea.css";

export default props => {
  if (!props.editing) {
    return viewing(props);
  }
  return editing(props);
};

function editing(props) {
  let value = Array.isArray(props.value) ? props.value.join("\n") : props.value;
  return (
    <div>
      <textarea
        className="txt txt--body-copy textarea"
        id={props.id}
        placeholder={props.placeholder}
        defaultValue={value}
        autoFocus={true}
        onChange={event => props.onChange(event.target.value)}
      />
    </div>
  );
}

function viewing(props) {
  let value = Array.isArray(props.value) ? props.value.join("\n") : props.value;
  return value ? (
    <div onClick={props.onClick}>
      {value.split("\n").map(val => {
        return val ? (
          <BodyCopy key={val} className="txt with--margin-0 ">{val}</BodyCopy>
        ) : (
          <br />
        );
      })}
    </div>
  ) : (
    <BodyCopy
      className="txt with--margin-0 txt--medium-2"
      onClick={props.onClick}
    >
      {props.placeholder}
    </BodyCopy>
  );
}
