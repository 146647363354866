import React from "react";
import Accordion from "../molecules/Accordion";


const SortOptions = (props) => {
  return props.options.map((option, index) => {
    return (
      <div className="filter-item__option-container flx flx--row" key={index}>
        <input
          className="filter-item__checkbox flx flx--center"
          disabled={option.disabled}
          id={option.title + index}
          type="checkbox"
          key={index}
          checked={option.selected}
          value={option.disabled}
          onChange={(e => props.handleCheckedboxChange(e, option.title))}
        />
        <label
            className={`txt txt--list-type filter-item__option-label${option.disabled ? " disabled" : ""}`}
            disabled={option.disabled}
            htmlFor={option.title + index}
          >
            {option.title}
        </label>
      </div>
    );
  });
};


export default function FilterItem(props) {
  const handleCheckedboxChange = (e, option) => {
    props.onFilterChange({
      filterTitle: props.objectKey,
      filterValue: option,
    });
  };

  return (
    <Accordion
      className="filter-item__container flx flx--column flx--end" 
      header={props.header}
      startExpanded={props.startExpanded}
    >
      <SortOptions
        handleCheckedboxChange={handleCheckedboxChange}
        options={props.options}
      />
    </Accordion>
  );
}
