export default {
    name: "Segments",
    nameSingular: "Segment",
    showInNav: true,
    route: "segments",
    type: "segments",
    urlList: "trail/trailsegments",
    url: "trail/trailsegment",
    fields: {
        name: {
            title: "Segment Name",
            tab: "Details",
            component: "Input"
        },
        map: {
            tab: "Details",
            placement: "right",
            component: "SingleImage"
        },
        photos: {
            tab: "Details",
            component: "ImageGrid"
        },
        sections: {
            tab: "Sections",
            component: "EntityList:sections"
        }
    },
    startingFields: ["name"],
    title: "{{name}}",    
    subtitle: "{{sections.length|0}} sections",
    jsonObjectName: "trail_segment_data",
    allowGlobalAdd: true,
    tabOrder: ["Details", "Sections"]
};
