import React from "react";
import "./EditingGroup.css";
import EditingButtons from "./EditingButtons";
import { Eyebrow } from "../atoms/Typeography";
import Icon from "../atoms/Icon";
import { getComponentFromString } from "../componentMap";
import store from "../../util/store";
import { satifiesCondition } from "../../util/utilFunctions";

class EditingGroup extends React.Component {
  saveTimeout = null;

  constructor(props) {
    super(props);
    // grab cached state:
    let entityVals = { ...props.entity };
    let editing = props.editingItems.reduce((isEditing, next) => {
      let storedValue = store.getItem(this.getId(next.key));
      if (storedValue != null) {
        entityVals[next.key] = storedValue;
      }
      return storedValue != null || isEditing;
    }, false);

    this.state = {
      saving: false,
      editing: editing,
      updatedEntity: entityVals
    };
  }

  componentDidUpdate(previousProps) {
    if (previousProps.entity === this.props.entity) {
      return;
    }
    // keep internally controlled props from being overwritten
    let internallyControlledProps = this.props.editingItems.reduce(
      (obj, item) => {
        obj[item.key] = this.state.updatedEntity[item.key];
        return obj;
      },
      {}
    );
    this.setState({
      updatedEntity: {
        ...this.props.entity,
        ...internallyControlledProps
      }
    });
  }

  engageEditingState() {
    if (this.state.editing) {
      return;
    }
    this.setState({ editing: true });
  }

  handleChange(id, propName, value) {
    let updates = { ...this.state.updatedEntity };
    updates[propName] = value;
    this.setState({
      updatedEntity: updates
    });
    this.cacheLocallly(id, value);
  }

  cacheLocallly(id, value) {
    // debouncing the save so we don't have constant churn.
    clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {
      store.setItem(id, value);
    }, 200);
  }

  getId(propName) {
    return [this.props.schemaItem.type, this.props.entity.id, propName].join(
      "-"
    );
  }

  save() {
    this.setState({ saving: true });
    this.props.entity.update(this.state.updatedEntity).then(() => {
      this.setState({ editing: false, saving: false });
      this.clearCache();
    });
  }

  clear() {
    this.setState({
      editing: false,
      updatedEntity: { ...this.props.entity }
    });
    this.clearCache();
  }

  clearCache() {
    this.props.editingItems.forEach(item => {
      let id = this.getId(item.key);
      store.removeItem(id);
    });
  }

  render() {
    let { title } = this.props;
    return (
      <div className="editing-group__container">
        <div className="flx flx--row with--b-margin-2">
          <Eyebrow className="with--margin-0">{title}</Eyebrow>
          <Icon
            name="editing"
            color="rgb(0, 117, 255)"
            className="with--h-margin-2 size--20 with--pointer"
            onClick={() => this.engageEditingState()}
          />
        </div>
        {this.props.editingItems.map(fieldDef => {
          let [componentName, compType] = fieldDef.component.split(":");
          let id = this.getId(fieldDef.key);
          let Comp = getComponentFromString(componentName);
          let value = this.state.updatedEntity[fieldDef.key];
          return satifiesCondition(
            fieldDef.conditions,
            this.state.updatedEntity
          ) ? (
            <Comp
              key={id}
              schemaItem={this.props.schemaItem}
              componentType={compType}
              value={value}
              showViewState={shouldShowViewState(fieldDef, value)}
              name={fieldDef.title}
              editing={this.state.editing}
              placeholder={fieldDef.placeholder}
              options={fieldDef.options}
              postfix={fieldDef.postfix}
              onChange={val => this.handleChange(id, fieldDef.key, val)}
              id={id}
              onClick={() => this.engageEditingState()}
            />
          ) : null;
        })}
        {this.state.editing ? (
          <EditingButtons
            onSave={() => this.save()}
            onCancel={() => this.clear()}
            disabled={false}
            saving={this.state.saving}
          />
        ) : null}
      </div>
    );
  }
}

function shouldShowViewState(fieldDef, value) {
  if (fieldDef.showViewState === false) {
    return false;
  }
  if (fieldDef.showWithoutValue === false && !value) {
    return false;
  }
  return true;
}

export default EditingGroup;
