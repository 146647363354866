/**
 * # Schema for the Asset entity.
 *
 */

export default {
    name: "Campsites",
    nameSingular: "Campsite",
    showInNav: true,
    index: 2,
    route: "campsites",
    type: "campsites",
    urlList: "trail/campsites",
    url: "trail/campsite",
    fields: {
        name: {
            title: "Campsite Name",
            tab: "Details",
            editingGroup: "Information",
            showViewState: false,
            component: "Input"
        },
        locationMiles: {
            title: "Trail Mile Marker",
            tab: "Details",
            editingGroup: "Information",
            showViewState: false,
            component: "Input"
        },
        segment: {
            title: "Segment",
            tab: "Details",
            editingGroup: "Information",
            component: "EntityLink:segments",
            placeholder: "Select segment..."
        },
        section: {
            title: "Section",
            tab: "Details",
            editingGroup: "Information",
            component: "EntityLink:sections",
            placeholder: "Select section..."
        },
        hammocks: {
            title: "Hammocks",
            tab: "Details",
            editingGroup: "Information",
            component: "Checkbox"
        },
        tentPad: {
            title: "Tent Pads",
            tab: "Details",
            component: "TextArea",
            placeholder: "Describe the tentpad(s)..."
        },
        firering: {
            title: "Fire Ring",
            tab: "Details",
            component: "TextArea",
            placeholder: "Describe the firering..."
        },
        furniture: {
            title: "Furniture",
            tab: "Details",
            component: "TextArea",
            placeholder: "Describe the furniture..."
        },
        foodStorage: {
            title: "Food Storage",
            tab: "Details",
            component: "TextArea",
            placeholder: "Describe the food storage situation..."
        },
        signage: {
            title: "Sign",
            tab: "Details",
            component: "TextArea",
            placeholder: "Describe the sign..."
        },
        latrine: {
            title: "Latrine",
            tab: "Details",
            component: "TextArea",
            placeholder: "Describe the latrine..."
        },
        waterSource: {
            title: "Water Source",
            tab: "Details",
            component: "TextArea",
            placeholder: "Describe the water source..."
        },
        map: {
            tab: "Details",
            placement: "right",
            component: "SingleImage"
        },
        photos: {
            tab: "Details",
            component: "ImageGrid"
        },
        projects: {
            tab: "Projects",
            component: "EntityList:projects"
        },
        people: {
            tab: "People",
            component: "EntityList:people"
        },
        files: {
            tab: "Files",
            component: "EntityList:fileLinks"
        },
        notes: {
            tab: "Notes",
            component: "Notes"
        }
    },
    tabOrder: [
        "Details",
        "Projects",
        // "People",
        "Files",
        "Notes"
    ],
    startingFields: ["name", "locationMiles"],
    jsonObjectName: "campsite_data",
    title: "{{name}}",
    subtitle: "Mile {{locationMiles|0}}",
    icon: "faFire"
};