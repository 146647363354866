import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheck } from "@fortawesome/free-solid-svg-icons";
import CircleImage from "../../molecules/CircleImage";
import { Headline3 } from "../../atoms/Typeography";
import "./PersonBulkAddListItem.css";

const FallBackHeadline = ({ fallbackName }) => (
  <Headline3 className="circle-image__fallback-text">
    {(fallbackName[0] || "").toUpperCase()}
  </Headline3>
);

const PersonBulkAddListItem = ({
  listItem: { id, photoLink, firstName, lastName, selected },
  onListItemClick
}) => {
  return (
    <li
      key={id}
      className="bulk-add-entity-list-item"
      onClick={() => onListItemClick(id)}
    >
      <CircleImage
        fallbackComponent={<FallBackHeadline fallbackName={firstName || ""} />}
        src={photoLink}
        fallbackName={firstName || ""}
        size="2.5em"
      />
      <div className="person-bulk-add__add-name-container">
        <span>{`${firstName} ${lastName}`}</span>
        {!selected ? (
          <FontAwesomeIcon icon={faPlus} className="person-bulk-add__fa-icon" />
        ) : (
          <FontAwesomeIcon
            icon={faCheck}
            className="person-bulk-add__fa-icon"
          />
        )}
      </div>
    </li>
  );
};

export default PersonBulkAddListItem;
