import React from "react";
import Note from "../molecules/Note";
import AddFeature from "../atoms/AddFeature";
import { AppContext } from "../../AppContext";

class Notes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new: false
    };
  }

  render() {
    let { entity, schemaItem } = this.props;
    return (
      <AppContext.Consumer>
        {appContext => (
          <div className="with--margin-2">
            <AddFeature
              title="Note"
              addHandler={() => this.setState({ new: true })}
            />
            {this.state.new ? (
              <Note
                appContext={appContext}
                entity={entity}
                index={"new"}
                schemaItem={schemaItem}
                refresh={() => this.setState({ new: false })}
              />
            ) : null}
            {(entity.notes || []).map((note, index) => (
              <Note
                appContext={appContext}
                key={note.date}
                entity={entity}
                index={index}
                schemaItem={schemaItem}
              />
            ))}
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

export default Notes;
