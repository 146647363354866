import React from "react";
import MainNavItem from "../molecules/MainNavItem";
import {
  objectToArray,
  getNestedValue,
  localTesting
} from "../../util/utilFunctions";
import schema from "../../data/schema";
import logo from "../../images/logo/superior-hiking-logo.png";
import "./MainNav.css";
import Button from "../atoms/Button";
import { Eyebrow } from "../atoms/Typeography";
import { AppContext } from "../../AppContext";

export const MainNav = () => {
  return (
    <AppContext.Consumer>
      {appContext => (
        <nav className="main-nav__container">
          <div className="main-nav__image-icon">
            <img src={logo} className="main-nav__logo" alt="logo" />
          </div>
          {objectToArray(schema)
            .filter(x => x.value.showInNav)
            .sort((a, b) => a.value.index - b.value.index)
            .map(x => (
              <MainNavItem
                key={x.value.name}
                name={x.value.name}
                type={x.value.type}
                routeName={x.value.route}
              />
            ))}
          <div className="with--border-top with--v-margin-2">
            {localTesting() ? (
              <SandboxInfo />
            ) : (
              <UserInfo appContext={appContext} />
            )}
          </div>
        </nav>
      )}
    </AppContext.Consumer>
  );
};

const SandboxInfo = props => (
  <div className="with--margin-1 flx flx--column">
    <Eyebrow className="with--margin-1 txt--align-center">Sandboxed</Eyebrow>
    <Eyebrow className="with--margin-1 txt--align-center txt--medium">
      Reset Sandbox To Default
    </Eyebrow>
    <Button
      className="with--margin-1 with--pointer"
      onClick={() => {
        localStorage.clear();
        window.location.reload();
      }}
    >
      Reset
    </Button>
  </div>
);

const UserInfo = ({ appContext }) => (
  <div className="with--margin-1 flx flx--column">
    <Eyebrow className="with--margin-1 txt--align-center">
      Logged in user
    </Eyebrow>

    <Eyebrow className="with--margin-1 txt--align-center">
      {getNestedValue(appContext, "user.username", "")}
    </Eyebrow>
    <Button
      className="with--margin-1 with--pointer"
      onClick={() => appContext.logout()}
    >
      Logout
    </Button>
  </div>
);
