import React from "react";
import {
  MapIcon,
  Project,
  Person,
  Tent,
  Signpost,
  MapPoint,
  EditPen,
  Search,
  File
} from "./SvgIcons";

const MAP = {
  campsites: Tent,
  projects: Project,
  sections: MapIcon,
  segments: MapIcon,
  people: Person,
  trailheads: Signpost,
  assets: MapPoint,
  editing: EditPen,
  search: Search,
  filelinks: File
};

const SvgIcon = props => {
  let name = props.name ? props.name.toLowerCase() : "";
  let SvgContent = MAP[name];
  let color = props.color || "#40c0f0";
  let size = props.size || 24;
  let classKey = props.uniqueKey || name;

  if (!SvgContent) {
    return null;
  }
  let styles = `.cls--${classKey}{fill:${color};}`;
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${size} ${size}`}
    >
      <defs>
        <style>{styles}</style>
      </defs>
      <SvgContent classKey={classKey} />
    </svg>
  );
};

export default SvgIcon;
