import React from "react";
import FilterItem from "./FilterItem";
import { Headline3, Eyebrow } from "../atoms/Typeography";
import "./Filters.css";

export default function FilterList(props) {
    return (<div className="flx flx--column filter-list__container">
        <div className="filter-header__container flx flx--row flx--space-between">
            <Headline3 className="filter-header__heading">Filters</Headline3>
            <Eyebrow
                className="filter-header__clear-all"
                onClick={props.onClearFilters}
                role="button"
            >
                Clear All
            </Eyebrow>
        </div>
        {props.filters.map((filter, index) => (
            <FilterItem
                key={index}
                className="flx flx--column"
                objectKey={filter.fieldName}
                header={filter.title}
                options={filter.options}
                value={filter.options}
                startExpanded={index === 0}
                onFilterChange={(e) => props.handleFilterChange(e)}
            />
        ))
        }
    </div>)
}
