import axios from "axios";
import {
    propHasValue
} from "../util/utilFunctions";

const DEFAULT_HEADERS = {
    "Content-Type": "application/json"
};

const InternalApi = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

function addPayload(methodName, config, payload) {
    if (!payload) {
        return config;
    }

    let propName = methodName === "get" ? "params" : "data";

    return Object.assign({}, config, {
        [propName]: payload
    });
}

/**
 * A factory method to build the request
 *
 * @param {string} methodName
 * @returns
 */
function apiCall(methodName) {
    /**
     * Closure for handling api requests.
     *
     * -- In the case of a GET request where there is no body, the body object will be converted to query params
     *
     * @param {string} url
     * @param {object} payload -- converted to params in the case of a get.
     * @returns
     */
    return (url, payload, configuration) => {
        let timeTracker = Date.now();
        let config = addDefaultHeaders(configuration);
        config.url = url;
        config.method = methodName;
        config = addPayload(methodName, config, payload);

        return new Promise((resolve, reject) => {
            InternalApi(config)
                .then((response) => {
                    globalResponseHandler(
                        response,
                        Date.now() - timeTracker
                    ).then((r) => resolve(r));
                })
                .catch((error) => {
                    globalErrorHandler(error).then((e) => reject(e));
                });
        });
    };
}

function globalResponseHandler(response, networkCallTimeMiliseconds) {
    // Do any network response handling here that needs to be done universally
    return Promise.resolve(response);
}

function globalErrorHandler(error) {
    console.error("server error: ", error);

    if (propHasValue(error, "response.status", 401)) {
        return Promise.resolve({});
    }
    return Promise.resolve(error);
}

function addDefaultHeaders(configuration) {
    configuration.headers = Object.assign(
        configuration.headers || {},
        DEFAULT_HEADERS
    );

    return configuration;
}

export const get = apiCall("get");
export const post = apiCall("post");
export const put = apiCall("put");
export const remove = apiCall("delete");

export default InternalApi;