import React from "react";
import "./Input.css";
import { ListType } from "../atoms/Typeography";

export default props => {
  if (props.editing) {
    return <CheckboxEditing {...props} />;
  }
  if (props.showViewState !== false) {
    return viewing(props);
  }
  return null;
};

class CheckboxEditing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: typeof props.value === "boolean" ? props.value : false
    };
  }

  onChange(_updatedValue) {
    this.setState({
      value: !this.state.value
    });
    this.props.onChange(!this.state.value);
  }

  render() {
    const { hideLabel, id, name, placeholder } = this.props;
    return (
      <div className="flx flx--row input__container">
        {hideLabel ? null : (
          <label
            className="txt txt--list-type input__label--editing"
            htmlFor={id}
          >
            {name + ":"}
          </label>
        )}
        <div className="flx flx--column flx--center">
          <input
            className="with--h-margin-1"
            id={id}
            disabled={!this.props.editing}
            placeholder={placeholder}
            type="checkbox"
            checked={this.state.value}
            autoFocus={true}
            onChange={event => this.onChange(event.target.value)}
          />
        </div>
      </div>
    );
  }
}

function viewing(props) {
  let value = typeof props.value === "boolean" ? props.value : false;
  let valueName = value ? "Yes" : "No";
  return (
    <div className="flx flx--row input__container">
      {props.hideLabel ? null : (
        <ListType className="input__label-title">{props.name}:</ListType>
      )}
      {
        <p
          className="txt with--margin-0 with--bold-weight input__viewing-value"
          onClick={props.onClick}
        >
          {valueName}
        </p>
      }
    </div>
  );
}
