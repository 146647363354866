import React from "react";
import PersonBulkAddListItem from "./PersonBulkAddListItem";

const ListItem = {
  people: PersonBulkAddListItem
};

const BulkAddListItem = props => {
  let C = ListItem[props.schemaItem.type];

  return <C {...props} />;
};

export default BulkAddListItem;
