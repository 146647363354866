import React from "react";
import ReactDOM from "react-dom";

const Toaster = ({ open, onClose, children, autoHideDuration = 1000 }) => {
  const el = document.createElement("div");
  React.useEffect(() => {
    document.body.appendChild(el);
    if (open) {
      setTimeout(() => {
        onClose();
      }, autoHideDuration);
    }
    return () => {
      document.body.removeChild(el);
    };
  });
  if (!open) return null;
  return ReactDOM.createPortal(children, el);
};

export default Toaster;
