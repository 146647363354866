import React from "react";
import "./CircleImage.css";
import { Headline2 } from "../atoms/Typeography";

export default function CircleImage({
  size,
  fallbackName,
  src,
  fallbackComponent
}) {
  return (
    <div
      className="circle-image__container"
      style={{ minWidth: size, minHeight: size }}
    >
      {src ? (
        <img
          className="circle circle-image__image"
          src={src}
          alt={fallbackName}
        />
      ) : (
        <div className="circle circle-image__fallback">
          {fallbackComponent ? (
            fallbackComponent
          ) : (
            <Headline2 className="circle-image__fallback-text">
              {(fallbackName[0] || "").toUpperCase()}
            </Headline2>
          )}
        </div>
      )}
    </div>
  );
}
