import React from 'react';
import { combineClassNames } from '../../util/utilFunctions';
import './Button.css'

export const BUTTON_TYPE = {
  primary: 'primary',
  secondary: 'secondary'
}

const Button = (props) => {
  let buttonTypeText = BUTTON_TYPE[props.buttonType] || BUTTON_TYPE.primary
  return (
    <button 
      className={combineClassNames('hdg hdg--button-txt button', buttonTypeText, props.className)} 
      type="button" 
      disabled={props.disabled}
      onClick={props.onClick || (() => {})}
    >
      {props.children}
    </button>
  )
}

export default Button