import React from "react";
import SectionCard from "./SectionCard";
import { Link } from "react-router-dom";
import PersonCard from "./PersonCard";
import TemplateCard from "./TemplateCard";
import ProjectCard from "./ProjectCard";
import TaskCard from "./TaskCard";
import FileLinkCard from "./FileLinkCard";

const Card = {
  sections: SectionCard,
  people: PersonCard,
  projects: ProjectCard,
  tasks: TaskCard,
  fileLinks: FileLinkCard,
  defaultCard: props => <TemplateCard {...props} />
};

const ListItemCard = props => {
  let C = Card[props.schemaItem.type] || Card.defaultCard;
  if (props.schemaItem.listNoLink === true) {
    return <C {...props} />;
  }

  let link = "/" + [props.schemaItem.route, props.entity.id].join("/");
  return (
    <Link className="with--no-decoration txt--color-dark" to={link}>
      <C {...props} />
    </Link>
  );
};

export default ListItemCard;
