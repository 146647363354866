import React from "react";
import { Headline2 } from "../atoms/Typeography";
import "./ListTitle.css";

export default function ListTitle(props) {
  // Here we need to add in the filter and handlers for Filter changes
  return (
    <div className="list-title__container">
      <Headline2>{props.schemaItem.name}</Headline2>
    </div>
  );
}
