export default {
    name: "Tasks",
    nameSingular: "Task",
    showInNav: false,
    route: "tasks",
    type: "tasks",
    urlList: "maint/tasks",
    url: "maint/task",
    fields: {
        name: {
            title: "Task Name",
            component: "Input"
        },
        completed: {
            title: "Completed",
            component: "Checkbox"
        },
        assignedTo: {
            title: "Assigned To",
            component: "EntityLink:people"
        }
    },
    startingFields: ["name", "assignedTo"],
    oneColumnList: true,
    listNoLink: true,
    navigateOnCreate: false,
    groupBy: {
        propName: "completed",
        map: {
            "false": "TO DO",
            "undefined": "TO DO",
            "true": "Completed",
        },
        orderMap: {
            "TO DO": 0,
            "Completed": 1
        },
        subtitle: "{{groupedItemValue}}"
    },
    jsonObjectName: "task_data",
    title: "{{name}}",
    subtitle: "Assigned To {{assignedTo}}",
    icon: "faIdCard"
};