import React from "react";

import { Eyebrow, Headline3 } from "../../atoms/Typeography";
import Card from "../../atoms/Card";
import { addChangeHandler, populateStringFromScope } from "../../../util/utilFunctions";
import { formatDDMMYY } from "../../../util/dateUtils";
import ShtaApi from "../../../api/ShtaApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faTimes } from "@fortawesome/free-solid-svg-icons";

import "./FileLinkCard.css";

export default class FileLinkCard extends React.Component {
    constructor(props) {
        super(props);
        // Instead of this subscription pattern, we should be moving to providers and context for managing 
        let entity = addChangeHandler(
            this.props.entity,
            this.props.schemaItem,
            new ShtaApi(),
            {
                goBack: () => {
                    props.regroup()
                }
            }
        );
        this.unsubscribe = entity.subscribe(newEntity => {
            if (props.regroup) {
                props.regroup();
            }
        });
        this.state = {
            entity: entity,
            disabled: false
        };
    }

    componentWillUnmount() {
        if (typeof this.unsubscribe !== "function") { return }
        this.unsubscribe()
    }

    render() {
        const { entity } = this.state;
        if (!entity || !entity.getSchema || entity.getSchema().type !== "fileLinks") {
            return null
        }
        const schemaItem = entity.getSchema()
        return (
            <Card padding={true}>
                <div className="file-link-card__content">                        
                    <a
                        className="file-link-card__link-container with--no-decoration"
                        href={entity.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                            <div className="file-link-card__share-icon-container">
                                {/* TODO: Migrate this icon to the one from Matt White */}
                                <FontAwesomeIcon icon={faExternalLinkAlt} color={"rgb(0, 117, 255)"} size="lg" />
                            </div>
                            <div className="flx flx--column">
                                <Eyebrow className="entity-title__sub-header txt--medium">
                                    {`${entity.fileType} | Added ${formatDDMMYY(entity.added, "/")}`}
                                </Eyebrow>
                                <Headline3 className="entity-title__header">
                                    {populateStringFromScope(schemaItem.title, entity)}
                                </Headline3>
                            </div>
                    </a>
                    <div className="file-link-card__remove-button" onClick={() => entity.remove()} role="button">
                        <FontAwesomeIcon icon={faTimes} color={"rgb(67, 84, 93)"} size="lg" />
                    </div>
                </div>

            </Card>
        );
    }
}
