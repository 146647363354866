import React, { Component } from "react";
import ShtaAPI from "../../api/ShtaApi";
import "./EntityList.css";
import LoadIndicator from "../atoms/LoadIndicator";
import Delete from "../atoms/Delete";
import Toaster from "../atoms/Toaster";
import FilterList from "./FilterList";
import GroupedEntityList from "./GroupedEntityList";

const FullScreenOverlay = (props) => {
  return (
    <div className={props.showOverlay ? "full-screen__overlay" : "hidden"}>
      {props.children}
    </div>
  );
};


/**
 * Component for handling a list of data.
 *
 * @class EntityList
 * @extends {Component}
 */
class EntityList extends Component {
  /**
   *Creates an instance of EntityList.
   * @param {object} props
   * @param {{update: (any) => Promise, [x: string]: string}} [props.parentEntity]
   * @param {string} [props.parentEntityPropertyName]
   * @param {{type: string}} [props.schemaItem]
   * @param {string[]} [props.itemIds]
   * @memberof EntityList
   */
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      listData: [],
      filteredListData: [],
      showDeleteConfirmation: false,
      itemPendingDeletion: null,
      showToaster: false,
      toasterMessage: "",
      selectedFilters: {},
      filters: [],
    };
  }

  componentDidMount() {
    this.requestList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.schemaItem !== prevProps.schemaItem) {
      this.setState({ 
        listData: [], 
        isLoading: true, 
        selectedFilters: {},
        filters: []
       });
      this.requestList();
    }
  }

  closeToaster() {
    this.setState({ showToaster: false });
  }

  requestList() {
    var shtaAPI = new ShtaAPI();
    this.setState({ listData: [], isLoading: true });
    shtaAPI
      .list(this.props.schemaItem.type, this.props.itemIds)
      .then(this.setupList);
  }

  setupList = (list) => {
    const listData = list.data || []
    this.setState({ 
      listData: listData,
      isLoading: false,
      selectedFilters: {},
      filteredList: listData,
      filters: this.getFilters(listData)
    });
  }

  /**
   * Setup the filters
   *
   * @param {[]} listData
   * @param {{[x: string]: []}} [selections]
   * @memberof EntityList
   */
  getFilters(listData, selections) {
    const schemaListData = this.props.schemaItem;
    return Object.keys(schemaListData.fields)
    .filter((key) => {
      return schemaListData.fields[key].options;
    })
    .map((key) => {
      const options = schemaListData.fields[key].options.map(opt => {
        return {
          title: opt,
          disabled: !listData.some(item => item[key] === opt),
          selected: selections && selections[key] ? selections[key].includes(opt) : false
        }
      })
      return {
        ...schemaListData.fields[key],
        options: options,
        fieldName: key,
      };
    });
  }

  onClearFilters = () => {
    this.setState({
      selectedFilters: {},
      filters: this.getFilters(this.state.listData),
      filteredList: this.state.listData,
    })
  }

  /**
   * Handles the filter change event.
   * 
   * @param {{filterTitle: string, filterValue: string}} filterChangeEvent
   * @memberof EntityList
   */
  handleFilterChange = (filterChangeEvent) => {
    const {filterTitle, filterValue} = filterChangeEvent;
    const { selectedFilters, listData } = this.state;
    const shouldRemove = selectedFilters[filterTitle] && selectedFilters[filterTitle].includes(filterValue);
    const newSelections = {
      ...selectedFilters,
      [filterTitle] : shouldRemove ? selectedFilters[filterTitle].filter(x => x !== filterValue) : (selectedFilters[filterTitle] || []).concat([filterValue])
    }

    const newSelectedFilters = Object.keys(newSelections).reduce((obj, nextKey) => {
      // only add back in a property if at least one of it's children is selected
      if (Object.keys(newSelections[nextKey]).length) {
        obj[nextKey] = newSelections[nextKey]
      }
      return obj
    }, {})

    const relevantFilters = Object.keys(newSelectedFilters)
    let filteredList = listData
    if (relevantFilters.length) {
      filteredList = listData.filter((item) => {
        return relevantFilters.every((propName) => {
          return newSelectedFilters[propName].some(val => {
            return item[propName] === val;
          })
        });
      })
    }
    
    this.setState({
      filters: this.getFilters(listData, newSelectedFilters),
      filteredList: filteredList,
      selectedFilters: newSelectedFilters,
    })
  }

  newItem(item) {
    if (this.props.parentEntity) { // We need to know if there is a parent entity
      let updatedEntity;
      let numberOfNewItems;
      if (Array.isArray(item)) {
        numberOfNewItems = item.length;
        updatedEntity = {
          ...this.props.entity,
          [this.props.parentEntityPropertyName]: this.props.itemIds.concat(
            ...[item.map(({ id }) => id)]
          ),
        };
      } else {
        numberOfNewItems = 1;
        updatedEntity = {
          ...this.props.entity,
          [this.props.parentEntityPropertyName]: this.props.itemIds.concat([
            item.id,
          ]),
        };
      }
      return this.props.parentEntity.update(updatedEntity).then(() => {
        const { schemaItem, parentEntity } = this.props;
        this.setState({
          showToaster: true,
          toasterMessage: `${numberOfNewItems} ${
            numberOfNewItems === 1 ? schemaItem.nameSingular : schemaItem.name
          } added to this ${parentEntity.getSchema().nameSingular}`,
        });
        this.requestList();
        return item;
      });
    }
  }

  onCloseDeleteConfirmationModal() {
    this.setState({
      showDeleteConfirmation: false,
      itemPendingDeletion: null,
    });
  }

  onDeleteChildListItemClick(entity) {
    this.setState({
      showDeleteConfirmation: true,
      itemPendingDeletion: entity,
    });
  }

  onDeleteChildListItem(item) {
    const { itemPendingDeletion } = this.state;
    const updatedEntity = {
      ...this.props.entity,
      [this.props.parentEntityPropertyName]: this.props.itemIds.filter(
        (id) => id !== itemPendingDeletion.id
      ),
    };
    return this.props.parentEntity.update(updatedEntity).then(() => {
      this.setState(
        {
          showDeleteConfirmation: false,
          itemPendingDeletion: null,
        },
        () => {
          this.requestList();
          return item;
        }
      );
    });
  }

  regroup() {
    this.requestList();
  }

  render() {
    return this.state.isLoading ? this.renderWaiting() : this.renderList();
  }

  renderWaiting() {
    return (
      <div className="waiting-text">
        <LoadIndicator size={25} />
      </div>
    );
  }

  renderList() {
    const { schemaItem, parentEntity } = this.props;
    const { filters, filteredList, selectedFilters } = this.state;
    return (
      <>
        <div className="entity-list__card-container" key={`list-schemaItem`}>
          <GroupedEntityList 
            schemaItem={schemaItem}
            parentEntity={parentEntity}
            listData={filteredList}
            selectedFilters={selectedFilters}
            onNewItem={(item) => this.newItem(item)}
            onDeleteItem={(item) => this.onDeleteChildListItemClick(item)}
            regroup={() => this.regroup()}
          />
          {
            filters && filters.length ? (
              <FilterList 
                filters={filters}
                handleFilterChange={this.handleFilterChange}
                onClearFilters={this.onClearFilters} />
            ) : null
          }
        </div>
        <FullScreenOverlay showOverlay={this.state.showDeleteConfirmation}>
          {this.state.showDeleteConfirmation && (
            <Delete
              onDelete={() => this.onDeleteChildListItem()}
              onCancel={() => this.onCloseDeleteConfirmationModal()}
              schemaItem={schemaItem}
              parentEntity={parentEntity}
              itemPendingDeletion={this.state.itemPendingDeletion}
            />
          )}
        </FullScreenOverlay>
        <Toaster
          open={this.state.showToaster}
          autoHideDuration={3000}
          onClose={() => this.closeToaster()}
        >
          <div className="toaster-container">
            <h4>{this.state.toasterMessage}</h4>
          </div>
        </Toaster>
      </>
    );
  }
}

export default EntityList;
