import React from "react";
import "./Input.css";
import { ListType } from "../atoms/Typeography";

const ValidationMap = {
  text: {
    type: "text",
    title: "Enter any text"
  },
  number: {
    type: "text",
    title: "Please enter a valid number",
    // pattern: "^d*.?d*$",
    allowedChars: ".0123456789-"
  },
  tel: {
    type: "text",
    title: "Please enter a valid phone number",
    pattern: "^d*.?d*$",
    allowedChars: "01234567890.-"
  }
};

export default props => {
  if (props.editing) {
    return <InputEditing {...props} />;
  }
  if (props.showViewState !== false) {
    return viewing(props);
  }
  return null;
};

class InputEditing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || ""
    };
  }

  onChange(updatedValue) {
    let newValue = this.format(updatedValue);
    this.setState({
      value: newValue
    });
    this.props.onChange(newValue);
  }

  format(newValue) {
    let allowedChars = (ValidationMap[this.props.componentType] || {})
      .allowedChars;
    // TODO: formatting things like phone numbers here based on type.
    if (this.props.componentType === "number") {
      if (newValue.slice(-1) === ".") {
        // if last char is a period, we are expecting other chars...
        return newValue;
      }
      let val = newValue.split("").reduce((str, next) => {
        return allowedChars.indexOf(next) > -1 ? str + next : str;
      }, "");
      val = parseFloat(val);
      return isNaN(val) ? 0 : val;
    }
    return newValue;
  }

  render() {
    const { hideLabel, id, name, placeholder, componentType } = this.props;
    const validation = ValidationMap[componentType] || { type: componentType };
    return (
      <div className="flx flx--row input__container">
        {hideLabel ? null : (
          <label
            className="txt txt--list-type input__label--editing"
            htmlFor={id}
          >
            {name}
          </label>
        )}

        <input
          className=" input"
          id={id}
          placeholder={placeholder}
          type={validation.type}
          pattern={validation.pattern}
          title={validation.title}
          value={this.state.value}
          autoFocus={true}
          onChange={event => this.onChange(event.target.value)}
        />
      </div>
    );
  }
}

function viewing(props) {
  return (
    <div className="flx flx--row input__container">
      {props.hideLabel ? null : (
        <ListType className="input__label-title">{props.name}:</ListType>
      )}
      {props.value ? (
        <p
          className="txt with--margin-0 with--bold-weight input__viewing-value"
          onClick={props.onClick}
        >
          {[props.value, props.postfix || ""].join(" ")}
        </p>
      ) : (
        <p className="txt with--margin-0 txt--medium-2" onClick={props.onClick}>
          {props.placeholder}
        </p>
      )}
    </div>
  );
}
