import React from "react";
import { Subhead, Eyebrow } from "../atoms/Typeography";
import Card from "../atoms/Card";
import EditingButtons from "./EditingButtons";
import TextArea from "./TextArea";
import store from "../../util/store";
import Input from "./Input";
import { formatDDMMYY } from "../../util/dateUtils";
import { getNestedValue } from "../../util/utilFunctions";

class Note extends React.Component {
  constructor(props) {
    super(props);
    let note = (props.entity.notes || [])[props.index];
    let storedValue = store.getItem(this.getId());
    let editing = storedValue || false;
    if (this.props.index === "new") {
      note = {
        date: Date.now(),
        title: "",
        note: "",
        username: getNestedValue(props.appContext, "user.username", "")
      };
      editing = true;
    }
    this.state = {
      editing: editing,
      note: storedValue || note
    };
  }

  engageEditingState() {
    if (this.state.editing) {
      return;
    }
    this.setState({ editing: true });
  }

  handleChange(fieldName, value) {
    let note = {
      ...this.state.note,
      [fieldName]: value
    };
    this.setState({
      note
    });
    this.cacheLocallly(this.getId(), note);
  }

  cacheLocallly(id, value) {
    clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {
      store.setItem(id, value);
    }, 200);
  }

  getId() {
    return [
      this.props.schemaItem.type,
      this.props.entity.id,
      "notes",
      this.props.index
    ].join("-");
  }

  save() {
    let notes = this.props.entity.notes || [];
    if (this.props.index === "new") {
      notes.unshift(this.state.note);
    }
    let updatedEntity = {
      ...this.props.entity,
      notes: notes.map(note =>
        note.index === this.props.index ? this.state.note : note
      )
    };

    this.props.entity.update(updatedEntity).then(() => this.finishSave());
  }

  finishSave() {
    this.setState({ editing: false });
    this.clearCache();
    if (this.props.refresh) {
      this.props.refresh();
    }
  }

  clear() {
    if (this.props.index !== "new") {
      this.setState({
        editing: false,
        value: this.props.entity.notes[this.props.index]
      });
    }
    if (this.props.refresh) {
      this.props.refresh();
    }
    this.clearCache();
  }

  clearCache() {
    store.removeItem(this.getId());
  }

  render() {
    return (
      <div className="with--margin-2">
        <Card padding={true}>
          <div className="flx flx--row">
            {/* <Eyebrow className="with--r-margin-2">
              {this.state.note.username}
            </Eyebrow> */}
            <Eyebrow className="txt--medium">
              {formatDDMMYY(this.state.note.date, "/")}
            </Eyebrow>
          </div>
          <div className="notes__title with--b-margin-1">
            <Input
              viewingComponent={Subhead}
              editing={this.state.editing}
              value={this.state.note.title}
              hideLabel={true}
              placeholder="Enter a title"
              onChange={val => this.handleChange("title", val)}
            />
          </div>
          {
            <div>
              <TextArea
                value={this.state.note.note}
                editing={this.state.editing}
                placeholder="Enter a note..."
                onChange={val => this.handleChange("note", val)}
              />
              {this.state.editing ? (
                <EditingButtons
                  onSave={() => this.save()}
                  onCancel={() => this.clear()}
                  disabled={false}
                />
              ) : null}
            </div>
          }
        </Card>
      </div>
    );
  }
}

export default Note;
