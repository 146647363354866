/**
 * # Schema for the people entity.
 *
 *  * There are two versions of this schema needed:
 *    * One for Landowners
 *    * One for volunteers
 */

export default {
  name: "People",
  nameSingular: "Person",
  showInNav: true,
  index: 4,
  route: "people",
  type: "people",
  urlList: "maint/persons",
  url: "maint/person",
  fields: {
    firstName: {
      title: "First Name",
      tab: "Details",
      editingGroup: "Information",
      showViewState: false,
      component: "Input"
    },
    lastName: {
      title: "Last Name",
      tab: "Details",
      editingGroup: "Information",
      showViewState: false,
      component: "Input"
    },
    personType: {
      title: "Person Type",
      tab: "Details",
      showViewState: false,
      editingGroup: "Information",
      component: "Dropdown",
      options: ["Landowner", "Admin", "Volunteer", "Volunteer Leader", "Neighbor"]
    },
    primaryPhone: {
      title: "Phone",
      tab: "Details",
      editingGroup: "Information",
      component: "Input:tel",
      placeholder: "Enter phone number"
    },
    emailAddress: {
      title: "Email",
      tab: "Details",
      editingGroup: "Information",
      component: "Input:email",
      placeholder: "Enter email"
    },
    title: {
      title: "Title",
      tab: "Details",
      editingGroup: "Information",
      conditions: ["personType:Admin", "personType:Volunteer", "personType:Volunteer Leader"],
      component: "Input",
      placeholder: "Enter title"
    },
    sectionMaintainer: {
      title: "Section Maintainer",
      tab: "Details",
      editingGroup: "Information",
      conditions: ["personType:Admin", "personType:Volunteer", "personType:Volunteer Leader"],
      component: "EntityLink:sections",
      placeholder: "Select section"
    },
    campsiteMaintainer: {
      title: "Campsite Maintainer",
      tab: "Details",
      editingGroup: "Information",
      conditions: ["personType:Admin", "personType:Volunteer", "personType:Volunteer Leader"],
      component: "EntityLink:campsites",
      placeholder: "Select campsite"
    },
    landType: {
      title: "Land Type",
      tab: "Details",
      editingGroup: "Land Agreement",
      conditions: ["personType:Landowner", "personType:Neighbor"],
      component: "Dropdown",
      options: [
        "National Forest",
        "State Park",
        "State Forest",
        "Tax Forfeit",
        "School Trust",
        "County",
        "City",
        "Private Individual",
        "Private Institution",
        "Other"
      ],
      placeholder: "Enter land type"
    },
    agreementType: {
      title: "Type",
      tab: "Details",
      editingGroup: "Land Agreement",
      conditions: ["personType:Landowner"],
      component: "Dropdown",
      options: ["Lease", "Easement", "Agreement", "Handshake", "No Agreement"],
      placeholder: "Select Type"
    },
    landStatus: {
      title: "Status",
      tab: "Details",
      editingGroup: "Land Agreement",
      conditions: ["personType:Landowner"],
      component: "Dropdown",
      options: ["Current", "Expired", "In Progress"],
      placeholder: "Select Status"
    },
    landAgreementRiskLevel: {
      title: "Risk Level",
      tab: "Details",
      editingGroup: "Land Agreement",
      conditions: ["personType:Landowner"],
      component: "Dropdown",
      options: ["Urgent", "High", "Medium", "Low"],
      placeholder: "Select Risk"
    },
    mailingAddress: {
      title: "Mailing Address",
      tab: "Details",
      component: "TextArea",
      conditions: ["personType:Landowner", "personType:Neighbor"],
      placeholder: "Enter address..."
    },
    description: {
      title: "Description",
      tab: "Details",
      component: "TextArea",
      placeholder: "Write a description..."
    },
    specialSkills: {
      title: "Special Skills",
      tab: "Details",
      component: "TextArea",
      conditions: ["personType:Admin", "personType:Volunteer", "personType:Volunteer Leader"],
      placeholder: "Enter any Special Skills..."
    },
    cadre: {
      title: "Cadre",
      tab: "Details",
      component: "TextArea",
      conditions: ["personType:Admin", "personType:Volunteer", "personType:Volunteer Leader"],
      placeholder: "Enter Cadre"
    },
    certifications: {
      tab: "Details",
      placement: "right",
      conditions: ["personType:Admin", "personType:Volunteer", "personType:Volunteer Leader"],
      component: "CertGroup"
    },
    projects: {
      tab: "Projects",
      component: "EntityList:projects"
    },
    files: {
      tab: "Files",
      component: "EntityList:fileLinks"
    },
    notes: {
      tab: "Notes",
      component: "Notes"
    }
  },
  tabOrder: [
    "Details",
    "Projects",
    "Files",
    "Notes"
  ],
  startingFields: ["firstName", "lastName", "personType"],
  title: "[{{personType}}] {{firstName}} {{lastName}}",
  jsonObjectName: "person_data",
  allowGlobalAdd: true,
  allowBulkAdd: true,
  groupBy: {
    propName: "personType",
    map: {
      Landowner: "Landowners",
      Neighbor: "Landowners",
      Admin: "Assigned People",
      Volunteer: "Assigned People",
      "Volunteer Leader": "Assigned People",
    },
    orderMap: {
      "Assigned People": 0,
      Landowners: 1
    },
    groupedAdd: {
      "Assigned People": {
        title: "People",
        overrides: {
          personType: "Volunteer"
        }
      },
      Landowners: {
        title: "Landowner",
        overrides: {
          personType: "Landowner"
        }
      }
    },
    subtitle: "{{groupedItemValue}}"
  }
};
