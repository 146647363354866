import React from "react";
import "./Card.css";
import { combineClassNames } from "../../util/utilFunctions";

export default function Card({className = null, children = null, padding = false, ...others}) {
  const outerClassName = combineClassNames("card__container", className);
  if (padding) {
    return (
      <div className={outerClassName} {...others}>
        {others.onCancel ? <div className="card__cancel-button" onClick={others.onCancel}>x</div> : null }
        <div className="card__inner-container">{children}</div>
      </div>
    );
  }
  return <div className={outerClassName} {...others}>{children}</div>;
}
