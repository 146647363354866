import React from "react";
import SvgIcon from "../../images/icon/SvgIcon";
import "./Icon.css"
/**
 *
 *
 * @export
 * @param {object} props
 * @param {{[x:string]: string}} [props.style]
 * @param {string} [props.className]
 * @param {() => any} [props.onClick]
 * @param {string} props.name
 * @param {string} props.color
 * @param {string} [props.uniqueKey]
 * @returns
 */
export default function Icon({ style, className, onClick, ...others }) {
  const clickHandler = onClick ? () => onClick() : null
  return (
    <div style={style} className={className} onClick={clickHandler}>
      <SvgIcon {...others} />
    </div>
  );
}
