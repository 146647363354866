import React from "react";
import {
  getNestedValue,
  populateStringFromScope
} from "../../util/utilFunctions";
import BulkAddListItem from "./BulkAddListItems/BulkAddListItem";
import Icon from "../atoms/Icon";
import "./TabList.css";

const TabList = ({ schemaItem, onListItemClick, listData }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [searchInputValue, setSearchInputValue] = React.useState("");

  return (
    <div className="tab-list-container">
      <div className="tab-list-header-container">
        <input
          onChange={e => setSearchInputValue(e.target.value)}
          type="text"
          className="tab-list-input"
          placeholder="Who do you want to add?"
          value={searchInputValue}
        />
        <span className="tab-list-icon-container">
          <Icon
            name="search"
            color="rgb(0, 117, 255)"
            className="main-nav-item__link__icon"
          />
        </span>
      </div>
      <ul className="tab-list-list-container">
        {listData.map((item, i) => {
          const groupedName = getNestedValue(
            item,
            `groupBy.groupedAdd.${item.groupedItemValue}`,
            {}
          );
          return (
            <li
              className="tab-list-tab"
              style={{
                borderBottom:
                  i === activeIndex ? ".7em solid rgb(0,117,255)" : ""
              }}
              key={groupedName.title}
              onClick={() => setActiveIndex(i)}
            >
              {groupedName.title}
            </li>
          );
        })}
      </ul>
      <div className="tab-list-list-item-container">
        {listData[activeIndex].list
          .filter(filterItem => {
            if (searchInputValue === "") {
              return true;
            }
            return populateStringFromScope(schemaItem.title, filterItem)
              .toLowerCase()
              .includes(searchInputValue.toLowerCase());
          })
          .map(listItem => (
            <BulkAddListItem
              key={listItem.id}
              schemaItem={schemaItem}
              listItem={listItem}
              onListItemClick={() => onListItemClick(listItem.id)}
            />
          ))}
      </div>
    </div>
  );
};

export default TabList;
