import React from "react";
import Select from "react-select";

import "./Dropdown.css";
import { ListType } from "../atoms/Typeography";

export default props => {
  if (props.editing) {
    return <DropdownEditing {...props} />;
  }
  if (props.showViewState) {
    return viewing(props);
  }
  return null;
};

class DropdownEditing extends React.Component {
  constructor(props) {
    super(props);
    let options = props.options || [];
    this.options = options.map(op => this.toOption(op));
    this.state = {
      selectedOption: this.toOption(this.props.value)
    };
  }

  toOption(val) {
    if (!val) {
      return null;
    }
    return {
      value: val,
      label: val
    };
  }

  onChange(updatedValue) {
    this.setState({
      selectedOption: updatedValue
    });
    this.props.onChange(updatedValue.value);
  }

  render() {
    const { selectedOption } = this.state;
    return (
      <div className="flx flx--row input__container">
        {this.props.hideLabel ? null : (
          <label
            className="txt txt--list-type input__label--editing"
            htmlFor={this.props.id}
          >
            {this.props.name}
          </label>
        )}

        <Select
          id={this.props.id}
          className="select"
          classNamePrefix="select"
          defaultValue={this.props.value}
          value={selectedOption}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isSearchable={true}
          onChange={val => this.onChange(val)}
          name={this.props.name}
          options={this.options}
        />
      </div>
    );
  }
}

function viewing(props) {
  return (
    <div className="flx flx--row input__container">
      {props.hideLabel ? null : (
        <ListType className="input__label-title">{props.name}:</ListType>
      )}
      {props.value ? (
        <p
          className="txt with--margin-0 with--bold-weight input__viewing-value"
          onClick={props.onClick}
        >
          {props.value}
        </p>
      ) : (
        <p className="txt with--margin-0 txt--medium-2" onClick={props.onClick}>
          {props.placeholder}
        </p>
      )}
    </div>
  );
}
